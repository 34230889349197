@import "../../../styles/mixins";

.product
{
    max-height: 275px;
    overflow-x: auto;
    right: 10 !important;
    left: 71%;
    top: 38.5% !important;
    position: absolute !important;
    z-index: 2000;
    margin-bottom: -50% !important;
    justify-content: right !important;  
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.wrapper {
    position: absolute !important;
    top: 100px;
    left: 65vw;
    max-width: 410px !important;
    max-height: 275px;
    z-index: 2000;
    overflow-x: auto;
    margin-left: 80px;
    margin-right: 50px;
    animation: fadeIn 0.5s ease-out;
    @include lowResolution { 
        overflow-x: auto;
        left: 60vw;
        top: 0px;
    }
}

.body {
    max-height: 250 !important;
    max-width: 200 !important;
}

.variableList .listItem {
    border: 0;
}


.variableListItem {
    margin-top: 16px;
    z-index: 2000;

    @include lowResolution {
        margin-top: 8px;
    }
}

.variableListItem .label {
    color: #546167;
    font-feature-settings: 'ss02' on;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.variableRow {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Always maintain 5 equal columns */
    gap: 16px; /* Space between grid items */
    justify-items: center; /* Center-align items horizontally */
    align-items: start; /* Align items to the top */
}

.variableRow .gridItem {
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column; /* Stack icon and title vertically */
    align-items: center; /* Center-align content horizontally */
    justify-content: flex-start; /* Align content to the top */
    width: 100%; /* Ensure consistent width */
    height: auto; /* Allow height to adjust based on content */
}

.variableRow .iconCard {
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    color: #5D8CA6;
    width: 60px;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F7F7;
    font-size: 21px;
}

.variableRow .title {
    margin-top: 8px; /* Add spacing between icon and title */
    color: #5D8CA6;
    font-size: 0.8rem;
    text-align: center;
    word-wrap: break-word; /* Handle long titles */

    @include lowResolution {
        font-size: .6rem;
        margin-top: 0px;
    }
}

.container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 200;
    color: white;
}

.container :global(.popover-arrow) {
    display: none !important;
}

.container :global(.popover) {
    margin-top: 50px;
}

.addBtn,
.staticBtn {
    
    border: 1px solid rgb(0, 181, 152);
    color: rgb(0, 181, 152) !important;
}

.addBtn:hover,
.staticBtn:hover {
    color: rgb(255, 255, 255) !important;
    background: rgb(69, 141, 128) !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(69, 141, 128) !important;
    border-image: initial;

}

.checked {
    color: rgb(255, 255, 255) !important;
    background: rgb(69, 141, 128) !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(69, 141, 128) !important;
    border-image: initial;
    margin: 10px 0;
}

.addChecked {
    z-index: 50;
    width: 120px;
    margin-top: 10px;
    position: absolute;
    right: 0;
    color: rgb(255, 255, 255) !important;
    background: rgb(69, 141, 128) !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(69, 141, 128) !important;
    border-image: initial;
}


.addBtn {
    z-index: 50;
    width: 120px;
    margin-top: 10px;
    position: absolute;
    right: 0;
}

.staticBtn {
    margin: 10px 0;
}

.ticketing {
    font-size: 20px;
    color:#5d8ca6;
  }