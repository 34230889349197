@import "./_variable.scss";

// #root>div>div.page {
//   background: $container-background;
// }

//  calendar
@import "~sweetalert2/src/sweetalert2.scss";

// --- for design flow start ---
:root {
  --primary: #00B598;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-base: "Public Sans", sans-serif;
$font-family-sans-serif: "Public Sans", sans-serif;
$theme-colors: (
  'primary': #00B598,
  'danger': rgb(255, 101, 101),
  'secondary': #00b8d4,
  'success': #6ec77c33,
  'dark': #000
);
$link-color: #00A4D6;

$tooltip-z-index: 20000;

@import '../node_modules/reactflow/dist/style.css';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/react-date-picker/dist/DatePicker.css';
@import '../node_modules/react-calendar/dist/Calendar.css';
@import '../node_modules/react-contexify/scss/main.scss';

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.react-date-picker__calendar {
  z-index: 1190;
}

.submitButton {
  width: 148px;
  padding: 8px 12px;
}

@import './botFlows/styles/styles.scss';

// --- for design flow end ---

@import "../node_modules/react-daterange-picker/dist/css/react-calendar.css";
// @import "./assets/plugins/bootstrap/css/bootstrap.min.css";
@import "./assets/scss/_variables.scss";
// custom Styles
@import "./assets/css/style.css";

@import "./assets/css/icons.css";

//transparent
//@import "./assets/css/style-transparent.css";
//switcher
@import "./assets/switcher/css/switcher.css";
@import "./assets/switcher/demo.css";

.DateRangePicker__CalendarSelection {
  background-color: #00B598 !important;
  border: 1px solid #00B598 !important;
}

a {
  text-decoration: none;
}

#root>div>div.page>div.open>div>aside>div>div:nth-child(3) {
  display: none;
}

.firefox-win10 {
  /* Use 'thin' to show a slimmer scrollbar.
     Note: Firefox does not support pixel-based widths. */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.no-button {
  border: 0;
  background-color: transparent;
  outline: none;
}

.main-nav-column .nav-item .nav-link.active::before {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: -28px;
  width: 3px;
  background-color: var(--primary-bg-color);
  border-radius: 3px;
  display: none;
}

.main-nav-column .nav-item .nav-link.active:hover,
.main-nav-column .nav-item .nav-link.active:focus,
.main-nav-column .nav-item .nav-link.active i,
.main-nav-column .nav-item .nav-link.active:hover i,
.main-nav-column .nav-item .nav-link.active:focus i {
  color: var(--primary-bg-color);
}

.progress {
  display: inherit;
}

.progress-bar {
  display: inherit;
}

.toast-header .btn-close {
  display: none !important;
}

.p-text .p-name {
  color: #3e464e;
  font-size: 14px;
  /* vertical-align: middle; */
  margin-bottom: 0;
  display: block;
  padding-bottom: 4px;
  line-height: 1.8;
}

.navbar-expand .navbar-toggler {
  display: block !important;
}

.collapse:not(.show) {
  display: none !important;
}

.accordiondclose {
  .accordion-header button {
    border: 1px solid $border;
    background-color: var(--primary02) !important;
  }
}

.accordiondclose {
  .accordion-header button.collapsed {
    border: 1px solid $border;
    background-color: #fff !important;
  }
}

.accordiondclose {
  .panel-heading1 .accordion-header button.collapsed {
    border: 1px solid $border;
    background-color: var(--primary02) !important;
  }

  .accor .accordion-header button.collapsed {
    border: 1px solid $border;
    background-color: var(--primary02) !important;
  }
}

.avatar-status {
  right: 5px;
  left: 55px;
  bottom: 17px;
}

.green {
  top: 120px;
  left: 45px;
}

.green1 {
  top: 195px;
  left: 45px;
}

.green2 {
  top: 275px;
  left: 45px;
}

.green3 {
  top: 350px;
  left: 45px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {

  .app-content,
  .horizontal-content {
    padding-top: 100px !important;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .app-content,
  .horizontal-content {
    padding-top: 50px !important;
  }

}

@media (min-width: 992px) {
  .navbar-toggler.navresponsive-toggler {
    display: none !important;
  }
}

@media (max-width: 991px) {


  .handle-counter .btn {
    padding: 5px 12px !important;
  }
}

.pricing-tabs .nav-price {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 40px;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}

.pricing-tabs .nav-price .nav-item {
  display: block;
}

.pricing-tabs .nav-price .nav-item a.active {
  background: var(--primary-bg-color);
}

.pricing-tabs .nav-price .nav-item a.active {
  color: #fff;
  transition: 0.3s;
}

.pricing-tabs .nav-price .nav-item a {
  padding: 12px 22px;
  display: block;
  background: #fff;
  font-size: 16px;
  border-radius: 0px;
  transition: 0.3s;
}

.attached-file-grid6 .icons li i:hover {
  color: #fff;
}

.attached-file-grid6 .icons li i:hover {
  border: var(--primary-bg-color);
  background: var(--primary-bg-color);
}

.attached-file-grid6 .icons li i {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: #fff;
  padding: 0;
}

.attached-file-grid6 .icons li i {
  color: var(--primary-bg-color);
  border: 1px solid #fff;
}

.btn-country {
  border-radius: 5px;
}

.error-page1 .tabs-menu1 ul li button.active {
  border-bottom: 3px solid var(--primary-bg-color) !important;
}

.error-page1 {
  .tabs-menu1 ul li button {
    border-bottom: 3px solid #e6ebf1 !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
  }

  .nav-tabs {
    border: none !important;
  }
}

.checkoutstepss .item .thumb img {
  display: inline-flex !important;
  width: 100px !important;
  height: 90px !important;
  justify-content: center !important;
  align-items: center !important;
  border: 0px solid #f2f2f2 !important;
}

.checkoutsteps .item .left {
  display: flex;
  align-items: center;
}

.checkoutstepss .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid $border;
  margin-bottom: 30px;
}

.checkoutstepss .item .purchase {
  display: inline-block;
  margin-left: 21px;
}

.card-pay .tabs-menu li button.active {
  background: var(--primary-bg-color) !important;
  color: #ffffff !important;
  width: 100%;
}

.card-pay .tabs-menu li button {
  width: 100%;
}

.card-pay .tabs-menu li button {
  padding: 0.7rem 1rem;
  display: block;
  text-align: center;
}

.card-pay .tabs-menu .nav-link {
  background: transparent !important;
  margin: 0px;
}

.main-header-notification .dropdown-toggle::after {
  display: none !important;
}

.main-header-message .dropdown-toggle::after {
  display: none !important;
}

.main-header-notification .dropdown-toggle::after {
  display: none !important;
}

.main-profile-menu .dropdown-toggle::after {
  display: none !important;
}

@media (min-width: 992px) {
  .main-content {
    margin-left: 240px;
  }

  .main-header {
    z-index: 800 !important;
    width: 100% !important;
    height: 56px !important;
    padding-left: 250px !important;
    position: fixed !important;
    top: 0px;
  }

  .app.sidenav-toggled .main-header {
    padding-left: 80px !important;
  }

  .app.sidenav-toggled .main-sidebar-header {
    width: 80px;
  }
}


.card.customs-cards {
  margin-bottom: 0px !important;
  border-bottom: 1px solid #e9edf4 !important;
}

.profile-tabs {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.profile-cover__info,
.profile-tab .main-nav-line .nav-item a.active {
  color: var(--primary-bg-color) !important;
  padding: 15px;
  background-color: transparent !important;
}

.profile-tab.tab-menu-heading .main-nav-line .nav-link {
  position: relative;
  padding: 4px 20px !important;
}

.sidebar .tabs-menu .nav-item .active {
  background: var(--primary-bg-color);
  color: #fff;
  border: 1px solid var(--primary-bg-color);
}

.sidebar .tabs-menu .nav-item a {
  padding: 8px 8px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #ebeaf1;
  font-weight: 500;
  background: transparent;
  color: $default-color;
  margin: 0 2px;
}

.header-icon {
  line-height: 1.8 !important;
  font-size: 1.3rem !important;
}

@media (min-width: 992px) {}

.fc .fc-button-primary {
  background-color: var(--primary-bg-color) !important;
}

.dropzone {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 200px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #334151;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border: 1px solid $border;
  -webkit-transition: border-color 0.15s linear;
  transition: border-color 0.15s linear;
  border-radius: 5px;
  z-index: 0;
}

.drop .dropzone:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(135deg,
      #e7ecf6 25%,
      transparent 25%,
      transparent 50%,
      #e7ecf6 50%,
      #e7ecf6 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(-45deg,
      #e7ecf6 25%,
      transparent 25%,
      transparent 50%,
      #e7ecf6 50%,
      #e7ecf6 75%,
      transparent 75%,
      transparent);
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.app-content .side-app {
  padding: 0px !important;
}

.main-content-left-mail .btn-compose {
  width: 100%;
}

.tx-40 {
  font-size: 40px !important;
}

//dashbord-1
.chart-circle {
  width: 7rem !important;
}

//range-sliders
@media (max-width: 991.98px) {
  .horizontal-main .hor-scroll {
    position: relative !important;

    .hover {
      overflow: scroll !important;
    }

    >div {
      position: relative !important;

      &:last-child {
        position: absolute !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .horizontal .horizontal-main {
    width: 100% !important;
    position: relative;
    bottom: initial;
  }
}

.transparent-theme .main-sidebar-header {
  backdrop-filter: blur(15px) !important;
}

.main-content-contacts {
  .main-nav-line .nav-link {
    border: none !important;
  }
}

.tree li {
  padding: 1em 1.5em 0.4em 1.5em !important;
}

.main-nav-line .nav-link {
  border: none !important;
}

.swal2-cancel {
  margin-right: 3px;
}

#accordion11 {
  .accordion-item {
    margin-bottom: 0px !important;
  }
}

.ratingcenter {
  display: contents !important;
  justify-items: center !important;
}

.hor-scroll>div:last-child>div {
  background-color: none !important;
  // fill: $dark-theme;
}

.hor-scroll>div:last-child>div::-webkit-scrollbar-thumb {
  background-color: $dark-theme !important;
}

.hor-scroll>div:last-child>div::-webkit-scrollbar-track {
  background-color: $dark-theme !important;
}

.hor-scroll>div:last-child>div:hover {
  background-color: $dark-theme !important;
}

.showcode {
  .form-check-input {
    width: 3em;
    margin-left: -3.7em;
    margin-top: -0.1rem;
    height: 1.6em;
    box-shadow: none;
  }

  .form-check-input:checked {
    background-color: $primary;
    border: 1px solid $border;
  }
}

.handle-counter span {
  float: left;
  text-align: center;
  height: 41px;
  border-radius: 0;
  width: 70px;
  border: 1px solid #ededf5;
  outline: none;
  border-left: 0;
  border-right: 0;
}


.export-table {
  .rdt_TableHead {
    border: 1px solid $border !important;
  }
}

.sidebarright2 div:nth-child(2) div {
  background-color: transparent !important;
}

.sidebarright2 div:nth-child(3) div {
  background-color: transparent !important;
}

.sidebar-right .side1 div:nth-child(3) div {
  background-color: transparent !important;
}

.chartjs-render-monitor {
  width: 300px !important;
  height: 315px !important;
  margin: 0 auto !important;
}

.swiper {
  z-index: 0 !important;
}

.main-profile-menu.show .dropdown-menu {
  display: block;
  top: 48px;
}

.search-icon {
  margin: auto 2px !important;
}

// .main-content .container,
// .main-content .container-fluid {
//   padding-left: 15px;
//   padding-right: 15px;
// }

.TrackInvertedSlider .MuiSlider-track {
  background: #bfebe4 !important;
  border: none !important;
}

.fc .fc-daygrid-body {
  z-index: 0 !important;
}

.fc-toolbar-chunk {
  .fc-button-group {
    margin-bottom: 4px !important;
  }
}

.light-theme.dark-theme .main-profile-menu .profile-user img {
  box-shadow: none !important;
}

@media (max-width: 540px) {
  .fc .fc-header-toolbar {
    display: block !important;
  }
}

.ql-wrapper {
  .rdw-colorpicker-modal-style-label {
    font-size: 14px;
    padding: 0 3px 5px;
  }
}

.stickyheader {
  .datatable .rdt_TableHead .rdt_TableHeadRow>div {
    border-right: none;
  }

  .rdt_TableHeadRow {
    border-bottom-width: 0px;
    border-bottom-color: transparent;
    border-bottom-style: none;
  }
}

.sc-llJcti select:focus-visible {
  outline: none;
}

@media (max-width: 420px) {
  .showcode {
    margin-top: 25px;
  }

  .dropdown-menu[data-popper-placement^="right"] {
    transform: translate3d(60.5px, 44px, 0px) !important;
  }

  .rtl .dropdown-menu[data-popper-placement^="right"] {
    transform: translate3d(-25.5px, 44px, 0px) !important;
  }
}

@media (max-width: 575px) {

  .bs-popover-start[data-popper-placement^="left"],
  .bs-popover-auto[data-popper-placement^="left"] {
    transform: translate3d(-20px, 1250px, 0px) !important;
  }

  .bs-popover-end[data-popper-placement^="right"],
  .bs-popover-auto[data-popper-placement^="right"] {
    transform: translate3d(41px, 1095px, 0px) !important;
  }
}

.rtl .tooltip-secondary1.bs-tooltip-end .tooltip-arrow::before,
.rtl .tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="end"] .tooltip-arrow::before {
  border-right-color: $primary;
}

.rtl .tooltip-primary1.bs-tooltip-start .tooltip-arrow::before,
.rtl .tooltip-primary1.bs-tooltip-auto[data-popper-placement^="start"] .tooltip-arrow::before {
  border-left-color: $primary;
}

.rtl {
  .uselistdata {
    .float-end {
      float: right !important;
    }
  }

  .rti--tag {
    button {
      margin-right: 5px;
    }
  }
}

.rti--container:focus-within {
  box-shadow: none !important;
}

.breadcrumb-3 li a {
  margin-right: 5px;
}

.breadcrumb-4 li a {
  margin-right: 5px;
}

@media (max-width: 320px) {
  .uselistdata .sc-llJcti {
    top: 85px;
  }

  .Toastify__toast-container--top-right {
    right: 0em !important;
  }

  .pagination-circled .page-link,
  .page-link-outline,
  .page-link {
    width: 33px;
    height: 36px;
  }

  .tabs-menu1 {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding: 10px 13px 11px 14px;
          display: block !important;
        }
      }
    }
  }

  .stickyheader .sc-llJcti {
    margin-top: 114px;
  }

  .fixedheader {
    label {
      width: 65%;
    }
  }

  .fileexport {
    .sc-ksZaOG {
      width: 50%;
    }
  }
}

@media (min-width: 321px) and (max-width: 753px) {
  .stickyheader .sc-llJcti {
    top: 0px;
    position: relative !important;
    left: 0px !important;
  }
}

.selectform__option {
  background-color: transparent !important;
}

.error-page1 {
  .form-control:focus {
    border-color: $border !important;
  }
}

.error-page1.dark-mode {
  .form-control:focus {
    border-color: $border !important;
  }
}

.details .msl-arrow-btn,
.msl-clear-btn {
  width: 20px;
  height: 25px;
}

.msl-options .msl-option:hover {
  color: $white;
  background: var(--primary-bg-color);
}

@media (max-width: 425px) {
  .basicsteps {
    i {
      display: none;
    }
  }
}

@media (min-width: 426px) and (max-width: 650px) {
  .basicsteps {
    i {
      display: block;
    }
  }
}

@media (min-width: 575px) and (max-width: 1360px) {
  .horwizard {
    i {
      display: block;
    }
  }
}

.intl-tel-input .country-list {
  white-space: normal;

  .country.highlight {
    background: transparent;
    border: none;
    margin: 0;
  }
}

.background__menu-list {
  .background__option {
    background: transparent !important;
    z-index: 99;
  }
}

.main-content-body-mail {
  .mail-option {
    ul {
      padding-left: 0rem !important;
    }
  }
}

@media (max-width: 992px) {
  #gallery img {
    width: 386px;
  }
}

.card-pay .tabs-menu.nav {
  background: #f0f3ff;
}

.tooltip {
  z-index: 0 !important;
}

.react-datepicker__header {
  background-color: $primary;
  color: $white;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: $white;
}

.react-datepicker__day--selected {
  background-color: $primary;
}

.datetimepicker-2 {

  .MuiFormControl-root,
  .MuiInputBase-root {
    display: block !important;
  }
}

.was-validated .is-loading>.form-control:valid,
.is-loading>.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem + 25px);
}

.rtl {

  .was-validated .is-loading>.form-control:valid,
  .is-loading>.form-control.is-invalid {
    padding-left: calc(1.5em + 0.75rem + 25px);
  }
}

.progress-nav ul li {
  display: flex;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: auto !important;
  font-weight: 500;
  font-size: 1.1rem;
}

.progress-nav ul .number {
  background-color: #eaedf7;
  color: #9197ab;
  font-size: 17px;
  font-weight: 400;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  font-style: normal;
}

.progress-nav {
  margin-top: 20px;

  .nav-pills {
    .nav-item {
      margin-top: -40px;

      .nav-link.active {
        background-color: transparent !important;

        .number {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}

.progress-nav {
  .nav-pills {
    .nav-link.done {
      .number {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

@media (max-width: 320px) {
  .tree li {
    padding: 1em 0.5em 0.4em 0.5em !important;
  }

  .progress-nav ul .number {
    width: 25px;
    height: 25px;
  }

  .wd25p {
    padding-right: 6%;
    padding-left: inherit;
  }

  .progress-nav .nav-pills .nav-item {
    margin-top: -35px !important;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .tree li {
    padding: 1em 0.5em 0.4em 0.5em !important;
  }

  .progress-nav ul .number {
    width: 25px;
    height: 25px;
  }

  .wd25p {
    padding-right: 8%;
  }

  .progress-nav .nav-pills .nav-item {
    margin-top: -35px !important;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .tree li {
    padding: 1em 0.5em 0.4em 0.5em !important;
  }

  .progress-nav ul .number {
    width: 25px;
    height: 25px;
  }

  .wd25p {
    padding-right: 11%;
  }

  .progress-nav .nav-pills .nav-item {
    margin-top: -35px !important;
  }
}

@media (min-width: 426px) and (max-width: 575px) {
  .progress-nav ul .number {
    width: 25px;
    height: 25px;
  }

  .wd25p {
    padding-right: 12%;
  }

  .progress-nav .nav-pills .nav-item {
    margin-top: -35px !important;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .progress-nav ul .number {
    width: 35px;
    height: 35px;
  }

  .wd25p {
    padding-right: 12%;
  }
}

.transparent-theme {
  .sidebar-right {
    .tabs-menu .nav-item a {
      border: 1px solid $transparent-border;
    }
  }
}

.color-menu,
.gradient-menu,
.light-menu,
.dark-menu {

  .slide-right,
  .slide-left {
    background-color: transparent !important;
  }
}

.MuiSlider-valueLabelOpen {
  background-color: $primary !important;
}

.formnotification .form-select:focus {
  border-color: $primary !important;
}

#example-collapse-text {
  .nav-tabs {
    .nav-item {
      button {
        padding: 5px;
        width: 100px;
      }
    }
  }
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.msl-input-wrp {
  .msl-input:before {
    color: $white !important;
  }
}

.borders {
  border-bottom: 1px solid $border;
  border-top: 0px solid $border;
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: $primary !important;
}

.msl-chip {
  background: transparent;
}

.input-group .is-invalid {
  z-index: 1;
}

.rdw-dropdown-wrapper:active {
  box-shadow: none;
}

.rdw-dropdown-wrapper:hover {
  box-shadow: none;
}

.feature-1 {
  right: 15px !important;
}

.nv-axisMin-y {
  display: none;
}

.nv-axisMax-y {
  display: none;
}

.dark-theme .main-mail-subject span {
  color: rgba(255, 255, 255, 0.6);
}

.transparent-theme {
  .text-transparent {
    color: $white !important;
  }
}

.tab_wrapper.right_side .nav-pills a:after {
  display: none;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
  border: 1px solid $primary !important;
}

.rmsc .dropdown-container {
  border: 1px solid $border !important;
}

.ReactModalPortal {
  z-index: 999999;
}

.dark-theme {
  .fileexport .rdt_TableHeader {
    background-color: $dark-theme;
  }

  .deleterow .rdt_TableHeader {
    background-color: $dark-theme;
  }
}

.deleterow {
  .sc-llJcti {
    top: 45px;
  }
}

.sc-ivTmOn {
  background: transparent !important;
  color: $primary !important;
}

#tree3 {
  .btn-group {
    button {
      background: $primary;
      color: $white;
    }
  }
}

.MuiTreeItem-content.Mui-selected:hover {
  background: transparent !important;
}

.MuiTreeItem-content.Mui-selected {
  background: transparent !important;
}

.MuiTreeItem-content:hover {
  background: transparent !important;
}

.selectproduct__control {
  box-shadow: none !important;
  border-color: $border !important;
}

.selectproduct__menu {
  .selectproduct__menu-list {
    .selectproduct__option:hover {
      background: $primary !important;
      color: $white !important;
    }
  }
}

.selectproduct__value-container {
  background: transparent !important;
}

.dark-theme {
  .breadcrumb-item+.breadcrumb-item::before {
    float: none;
    padding-right: 0.5rem;
    color: #d6dfe7;
  }
}

.rtl {
  .btn-icons {
    margin-right: 0.8rem !important;
    margin-left: inherit !important;
  }

  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
    margin-left: 3px;
  }
}

.bg-gray-100 {
  .nav-tabs {
    .nav-link.active {
      background-color: $primary;
      color: $white;
      border-radius: 5px;
    }
  }
}

.react-datepicker__year-text--selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: $primary !important;
}

.dark-theme {
  .rmsc .dropdown-container {
    background: $dark-theme;
    border: 1px solid $border-dark !important;
  }

  .rmsc {
    --rmsc-main: #000;
    --rmsc-selected: #38cab3;
    --rmsc-hover: #38cab3;
    --rmsc-bg: #2a2e3f;
    --rmsc-gray: #fff;
  }
}

.light-theme .main-form-group {
  padding: 0 15px;
}

.input-group .is-invalid {
  z-index: 0 !important;
}

.rdw-dropdown-optionwrapper .rdw-dropdownoption-highlighted:hover {
  color: $white;
  background: $primary;
}

.transparent-theme {
  .rmsc .dropdown-container {
    background: $transparent-theme;
    border: 1px solid $transparent-border !important;
  }

  .rmsc {
    --rmsc-main: #000;
    --rmsc-selected: #38cab3;
    --rmsc-hover: #38cab3;
    --rmsc-bg: #38cab3;
    --rmsc-gray: #fff;
  }
}

.transparent-theme {
  .MuiTreeItem-iconContainer {
    svg {
      color: $white !important;
    }
  }

  .MuiTreeItem-label {
    color: $white
  }
}

.rtl {
  .MuiTreeItem-iconContainer {
    margin-left: 4px;
  }
}

.w-250 {
  margin-inline: auto !important;
  width: 250px !important;
}

.h-275 {
  height: 275px !important;
}

.textStyle {
  font-size: 16px;
  text-align: center;
  position: relative;
  margin-top: 4%;
  font-weight: 600;
  color: black;
}

.centered-form {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
  position: relative;
  top: 3%;
}

.signUpBtn {
  background: #00B598;
  border: #00B598;
  margin-top: 10px;
  color: #fff;
  box-shadow: none;
  font-size: 14px;
}

.signUpBtn:hover {
  background: #458d80;
  border: #458d80;
  margin-top: 10px;
  color: #fff;
  box-shadow: none;
  font-size: 14px;
}

.signUpBtn:focus {
  background: #00B598;
  border: #00B598;
  margin-top: 10px;
  color: #fff;
  box-shadow: none;
  font-size: 14px;
}

button.signUpBtn:active {
  background: #00B598 !important;
  border-color: #00B598 !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.signUpBtn:disabled {
  background: #86b5ac;
  border: #86b5ac;
  color: #fff;
  box-shadow: none;
  cursor: not-allowed;
}

.input-group {
  display: flex;
  align-items: center;
}

.getStartedBtn {
  background: #00B598;
  border: #00B598;
  margin-top: 5px;
  color: #fff;
  box-shadow: none;
  font-size: 12px;
}

.getStartedBtn:hover {
  background: #458d80;
  border: #458d80;
  margin-top: 5px;
  color: #fff;
  box-shadow: none;
  font-size: 12px;
}

.getStartedBtn:focus {
  background: #00B598;
  border: #00B598;
  margin-top: 5px;
  color: #fff;
  box-shadow: none;
  font-size: 12px;
}

button.getStartedBtn:active {
  background: #00B598 !important;
  border-color: #00B598 !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 12px !important;
}

.getStartedBtn:disabled {
  background: #86b5ac;
  border: #86b5ac;
  color: #fff;
  box-shadow: none;
  cursor: not-allowed;
}

.mobile-login-flag .input-group {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
}

.input-group-prepend {
  // border-right: 2px solid #B7B7B7;
  width: 20%;
}

.hideFileInput {
  display: none;
  opacity: 0;
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black;
  box-shadow: none;
  border-color: #007EC3;
}

.changeBorderColor {
  border: 1px solid #007EC3;
}

.iconEyeBg {
  background: none;
  height: 40px;
  border-left: none;
}

.iconsColor {
  color: #666E7D;
  cursor: pointer;
}

.form-control {
  border: 1px solid #E2E2E2;
}

// .form-control:disabled {
//   background: #FBFBFB;
// }

// .form-label {
//   font-weight: 500;
//   font-size: 0.875rem;
//   color: #4a4a69;
// }

.swal2-confirm {
  background-color: #007ec3 !important;
  color: white !important;
}

.cardStyle {
  border: 1px solid #DCDFE3;
  box-shadow: none;
  font-size: 13px;
  height: 50vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
}

.hideBorder {
  border: none;
}

.hideBorder:focus {
  border: none !important;
}

.hideRBorder {
  border-right: none;
}

.hideLBorder {
  border-left: none;
}

.searchIcon {
  background: white;
  height: 40px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.convCancelBtn {
  background: #DCDFE3;
  border: #DCDFE3;
  margin-top: 10px;
  box-shadow: none;
  font-size: 14px;

  &:hover {
    background: #DCDFE3;
    border: #DCDFE3;
    margin-top: 10px;
    box-shadow: none;
    font-size: 14px;
  }

  &:focus {
    background: #DCDFE3;
    border: #DCDFE3;
    margin-top: 10px;
    box-shadow: none;
    font-size: 14px;
  }
}

.startConv {
  display: flex;
  justify-content: flex-end;
}

::selection {
  background: #4285f4 !important;
  color: #fff;
}

.fontWeight {
  font-weight: 600;
}

.sidebarItems {
  display: flex;
  align-items: center;
  margin-left: 11%;
  font-size: 12px;
  width: 85%;
}

.iconToLeft {
  margin-left: 31%;
}

.errorMsg {
  color: #ff6565;
  font-size: 12px;
  margin-top: 2% !important;
}

.centerItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

h6.headerOne {
  color: #000;
  font-size: 16px;
  font-style: normal;
  padding: 0px;
  margin-top: 7px;
}

.borderLine {
  background: linear-gradient(90deg, #007EC3 0%, rgba(0, 126, 195, 0.00) 100%);
  width: 149px;
  height: 3px;
  flex-shrink: 0;
}

.expRealContainer {
  .headTitle p {
    padding: 0px;
    margin: 10px 0px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .content {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
}

.centerItemsWithWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.failedMsgBg {
  background-color: #FFF5F5 !important;
  // color: #ff6565;
  //background-color: #f8f8f8;
}

.lastLoginTime {
  padding-left: 5px;
  color: gray;
  font-size: 10.5px;
}

.icon-hover {
  color: #8a929d;
  height: 15px;
}

.icon-hover:hover,
.icon-hover:focus,
.icon-hover:active {
  color: #00b598 !important;
}

.rupeeFont {
  font-family: 'Noto Sans', sans-serif !important;
}

.invoiceDownload {
  cursor: pointer;
  font-size: 17px;
}

.redirectInvoice {
  color: rgb(0 184 212);
}

.react-bs-table-no-data {
  display: none;
}

.jsoneditor table {
  table-layout: auto !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  color: #000;
  background: white;
}

.side-app button:disabled {
  cursor: not-allowed !important;
}

.react-time-picker__inputGroup__input:invalid {
  background: white !important;
}

.upgradeBtn {
  border: 1px solid #00B598;
  color: #00B598;
  border-radius: 25px;
  box-shadow: 1px 3px 2px 1px rgb(219, 212, 212);
  padding: 4px 10px;
  font-size: 13px;
  position: relative;
  left: 6px;
  bottom: 1px;
}

.upgradeBtn:hover {
  border: 1px solid #458d80;
  color: #ffff;
  background: #458d80;
  border-radius: 25px;
  box-shadow: 1px 3px 2px 1px rgb(219, 212, 212);
  padding: 4px 10px;
  font-size: 13px;
  position: relative;
  left: 6px;
  bottom: 1px;
}

.scheduleMeeting {
  color: rgb(74, 74, 74);
  border: 1px solid rgb(74, 74, 74);

  &:hover {
    color: #ffff;
    background: rgb(74, 74, 74);
  }

  &:active {
    color: #ffff;
    background: rgb(74, 74, 74);
  }
}

.withoutChannelBtn {
  width: 35% !important;
}