$primary-dark: #00b598;

@media only screen and (min-width: 320px) and (max-width: 480px) {}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}

#Conversation-Container>div.messageColumn.mt-2.col-md-8>div>div.p-1.bg-white.card-header>div>div>div.p-0.d-lg-flex.justify-content-lg-end.col-sm-6.col-lg-2>div>div.ml-2.memberWidth.css-b62m3t-container>div>div.css-1hb7zxy-IndicatorsContainer {
  z-index: 1 !important;
}

.err_mess_row {

  .col-lg-4,
  .col-lg-1,
  .col-lg-7 {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .col-lg-1 {
    justify-content: end;
  }

  .col-lg-7 {
    padding-left: 0;
    color: #4a5568;
    font-size: small;
  }

  .failed-message {
    display: flex;
    justify-content: center;
    align-items: center;

    .failed-avatar {
      background: #fed7e2;
      color: #718096;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.5rem;
      margin-right: 0.2rem;
    }
  }

  .mess_status {
    background: #fed7d7;
    color: #c53030;
    font-size: smaller;
    text-align: center;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 8px;
  }
}

.err_btn {
  margin-top: 1rem;
  display: flex;
  justify-content: end;

  button {
    border: none;
    padding: 4px 12px;
    border-radius: 4px;
    background: $primary-dark;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

.contactListLoader {
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  text-align: center;
}

// for emoji picker
.epr-preview {
  display: none !important;
}

.conversationBgImg {
  background-image: url("../../assets/img/ChatbackgroundImg.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Align children at the start (top) */
  height: 73.5vh;
  position: relative;
  padding: 2% 0% 2% 2%;

  .blockedConvoMescsage {
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    color: #dc3545;

    .btn {
      margin-left: 16px;
      border: 1px solid #efefef;
      color: #6ec77c;
    }
  }

  .timeoutConvoMessage {
    width: 100%;
    cursor: text;
    display: flex;
    justify-content: center;
    color: #5d5d5d;
    font-size: 1em;

    .caption {
      text-align: center;
      margin: 12px auto;
      margin-bottom: 30px;

      .title {
        display: block;
        color: #333;
        font-size: 1.2em;
        font-weight: 500;
      }
    }
  }
}

.conversationBgImg div:last-child {
  margin-top: auto;
  /* Push the last div to the bottom */
}

hr {
  margin: 0px;
}

.conversationBgImg div:last-child {
  margin-top: auto;
  /* Push the last div to the bottom */
}

.messagesDesign {
  padding: 5px;
  margin-top: 7px;
  border-radius: 5px;
  word-wrap: break-word;
  position: relative;

  .convMediaImg {
    // max-width: 275px;
    width: 285px;
    height: 200px;
    padding: 2px;

    @media (max-width: 1366px) {
      max-width: 300px;
    }
  }
}

// .failMsgBox {
//     position: relative;
//     // left:70%;
//     right: 2%;
//     top:0%
// }

// .failedMessage {
//     position: relative;
//     right: -71%;
//     top:13%;
//     background:  #F8F8F8;
//     width: 10%;
//     height: 12%;
//     background-color: #dad6d6;
//     color:white;
// }

// .ritAlign {
//     align-self: flex-end !important;
//     width: auto;
// }

.outgoingMsg {
  align-self: flex-end !important;
  background: #EFFFDF;
}

.reaction-icons {
  display: none;
  position: absolute;
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // opacity: 0;
  // transition: visibility 0s, opacity 0.5s ease;
}

.reactionIconsVisible {
  display: flex !important;
}

// .messageContainer:hover .reaction-icon {
//     display: flex;
// }
// .messageContainer:focus-within .reaction-icon {
//     display: flex;
// }
// .reaction-icon:hover .reaction-icon{
//     display: flex;

// }

.reaction-icons-left {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // opacity: 0;
  // transition: visibility 0s, opacity 0.5s ease;
}

.reaction-icon {
  display: none;
}

.emoji-icon {
  position: absolute;
  left: -1%;
  top: 107%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  font-size: 14px;
  // background-color: #F0EBEB;
  background-color: #ebedee;
  border-radius: 25px;
  padding: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.emoji-icon-img {
  position: absolute;
  left: -1%;
  top: 98%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  font-size: 14px;
  // background-color: #F0EBEB;
  background-color: #ebedee;
  border-radius: 25px;
  padding: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.emoji-icon-notImg {
  position: absolute;
  left: 3%;
  top: 88%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  font-size: 14px;
  // background-color: #F0EBEB;
  background-color: #ebedee;
  border-radius: 25px;
  padding: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.component-reaction {
  position: absolute;
  top: 95%;
  left: 3%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 25px;
  font-size: 14px;
  // background-color: #F0EBEB;
  background-color: #ebedee;
  border-radius: 25px;
  padding: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 1);
  // z-index: 1000000000000000000000;
}

.reply-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #f7f6f6;
  padding: 10px;
  position: absolute;
  bottom: 70px;
  left: 12px;
  width: 97%;
  // position: relative;
  // // margin: 10px 0;
  // width: 99%;
  // margin-left: 0px;
  // margin-right: 5px;
  // margin-bottom: -14px;

  // // margin: -8px;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.close-button {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
  font-size: 1.5em;
}

.reply-content {
  border-left: 4px solid green;
  padding-left: 8px;
}

.message-text {
  margin-top: 0;
  font-size: 1em;
}

.sender-info {
  display: block;
}

.sender-name {
  font-weight: bold;
  display: block;
  /* makes the name appear below the message */
}

.sender-number,
.timestamp {
  font-size: 0.8em;
  color: #90939f;
  display: block;
  /* or inline-block, depending on how you want them to flow */
}

.reply-body {
  padding-left: 12px;
}

.incomingMsg {
  align-self: flex-start !important;
  background: #ffffff;
}

.convTypes {
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 15px 2px;
  cursor: pointer;
}

.conversationTabs {
  cursor: pointer;
  flex: 1 0 auto;
}

.activeConv {
  font-weight: 500 !important;
  position: relative;
}

.activeConv::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -6px;
  height: 1.5px;
  background-color: #007ec3;
}

.convTypesBorder {
  position: relative;
  bottom: 9px;
  // margin-top: -10px;
}

.contact_names {
  border-radius: 50%;
  color: #fff;
  font-weight: 900;
  width: 2.7rem;
}

.contactNames {
  border-radius: 50%;
  color: #fff;
  font-weight: 900;
  width: 3.5rem;
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  // width: 40px;
  // height: 40px;
  // margin-top: 1px;
  // background-color: #D9D9D9;
  // margin-right: 10px;
  //position: relative;
  // margin-top: 3px;
}

.conFirstName {
  bottom: 4px;
}

.alignContacts {
  display: flex;
  // align-items: center;
  padding: 3px 8px;
  cursor: pointer;
  position: relative;

  .hoverOptionsOverlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    // background-color: #fff;
    background-color: transparent !important;
    padding: 10px 12px;

    .optionBtn {
      background-color: transparent !important;
      font-size: 1.2rem;
    }
  }

  &:hover {
    background: rgba($color: #aad2f3, $alpha: 0.1);
    border-radius: 4px;

    .conversationTime {
      display: none;
    }

    .hoverOptionsOverlay {
      display: block;
    }

    .conversationStatus {
      display: none;
    }
  }

  &.selected {
    border-left: 3px solid #c5d4db;
    background-color: rgba($color: #aad2f3, $alpha: 0.1);
    border-radius: 4px;

    .hoverOptionsOverlay {
      background-color: transparent;
    }
  }
}

.messagesContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh;
  padding-right: 1em;
  position: relative;

  .timestampBlock {
    max-width: 100px;
    min-width: 90px;
    margin: 12px auto;
    background-color: #f4f5f7;
    text-transform: uppercase;
    color: #474747;
    text-align: center;
    padding: 2px 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-size: 10px;
  }

  .memberChangeBlock {
    max-width: 100px;
    min-width: 400px;
    margin: 12px auto;
    background-color: #f4f5f7;
    text-transform: lowercase;
    color: #474747;
    text-align: center;
    padding: 2px 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    font-size: 10px;
  }

  .convLoading__wrapper {
    margin-top: 28vh !important;
  }
}

.messagesContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 126, 195, 0.2);
}

.createdTime {
  font-size: 9px;
  color: #6c6161;
  padding-top: 3.3px;
  float: right;
}

.deliveredTick {
  color: rgb(21, 165, 221);
}

.doubleTick {
  letter-spacing: -3px;
}

.statusDesign {
  font-size: 11px;
  font-weight: 600;
}

.convSearchWidth {
  width: 100%;
}

.filterPanel {
  display: flex;
  flex: 3;
  // justify-content: space-between;
}

.convSelectedPanel {
  padding: 10px;
  margin-top: 16px;
  display: flex;

  .convSelectedPanel__caption {
    .btn {
      margin-right: 6px;
    }

    .convSelectedPanel-caption__title {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  .btn {
    background: transparent !important;
    border: none !important;
    outline: none;
    border-radius: 20%;
    padding: 7px;

    &.with-border {
      border: 2px solid #007bff;
    }

    &:focus {
      background: transparent !important;
      border: none !important;
      color: unset !important;
      outline: none !important;
    }
  }
}

.convWidth {
  width: 70%;
}

.searchContainer {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--gray-400, #dcdfe3);
  height: 14%;
}

.contactBorder {
  border-radius: 4px;
  border: 1px solid var(--gray-400, #dcdfe3) !important;
  background: transparent !important;
  padding: 8px;
  cursor: pointer;
  height: 9%;
}

.optionBorder {
  border-radius: 4px;
  border: 1px solid var(--gray-400, #dcdfe3) !important;
  background: transparent !important;
  padding: 8px;
  cursor: pointer;
}

.closeIcon {
  position: relative;
  left: 2px;
}

.errMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  flex-direction: column;
  overflow: hidden;
}

.convUploadMessage:focus::placeholder {
  color: transparent;
}

.contacts {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #00b598;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.contactPanel {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}

// .contactPanel>div:nth-child(2) {
//     display: flex;
//     align-items: center;
// }

.contactPanel span {
  display: inline-block;
  margin-right: 8px;
}

.convsearchIcon {
  width: 2%;
}

.contactsList {
  max-height: 62vh;
  overflow-y: auto;
  margin-top: 5px;
  padding-right: 10px;
}

// .contactsList::-webkit-scrollbar {}


.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  // margin-bottom: 0.5em;

  .btn-link {
    color: black !important;
  }

  .btn.contextMenu {
    background: transparent !important;
    border: none !important;
    font-size: 14px;
    margin-left: -12px;
  }
}

.ctxMenu {
  .ctxMenuItem {
    background: transparent !important;
    border: none !important;
    color: rgb(0, 126, 195);

    &:hover {
      background: #efefef !important;
      cursor: pointer;
    }
  }

  .ctxMenuSubtitle {
    text-transform: uppercase;
    color: #767676;
    font-size: 0.75rem;
    margin: 5px;
    margin-left: 12px;
  }
}

.contactClose {
  position: relative;
  bottom: 24px;
  left: 10px;
  cursor: pointer;
}

.createContactBtn {
  background: #ff7342;
  border: #ff7342;
  color: #fff;
  box-shadow: none;
  font-size: 14px;

  &:hover {
    background: #ff7342;
    border: #ff7342;
    box-shadow: none;
  }

  &:focus {
    background: #ff7342;
    border: #ff7342;
    box-shadow: none;
  }
}

.addContactHeader {
  font-size: 16px;
  font-weight: 600;
  margin-left: 25px;
}

.searchOptBorder {
  border-right: 1px solid var(--gray-400, #dcdfe3);
  height: 100%;
}

.messagesOption {
  position: relative;
  top: 24%;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.messageInput {
  background: white;
  border-radius: 5px;
  width: 99%;
  position: relative;
  top: 8px;
  right: 4px;

  .reactQuillInput {
    max-height: 60vh;
    overflow: auto;
  }
}

.notesFontSize {
  font-size: 11px;
}

.slanted {
  transform: rotate(-10deg);
}

.selectedTool {
  color: #007ec3;
}

.msgCount {
  // position: relative;
  // right: 17px;
  // bottom: -25px;
  position: absolute;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  width: 20px;
  height: 18px;
  border-radius: 50%;
  background: var(--banner-red, #f1592a);
  color: white;
  font-size: 8.5px;
  text-align: center;
  line-height: 18px;
  font-weight: 550;
}

.overAllCounts {
  margin-left: 7px;
  margin-top: -7px;
  position: absolute;
  width: 21px;
  height: 16px;
  padding: 2px 3px;
  border-radius: 25%;
  color: white;
  font-size: 9px;
  font-weight: 550;
  text-align: center;
  line-height: 12px;
}

.convScroll {
  max-height: 70vh;
  min-height: 15vh;
  overflow-y: auto;
  padding-right: 8px;

  .convHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .convCaption {
      display: block;
      color: #a3a3a3;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.convLoading__wrapper {
  display: flex;
  justify-content: center;

  .convLoading {
    display: flex;
    align-items: center;

    .loadingText {
      margin-left: 5px;
      color: #a3a3a3;
    }
  }
}

.convScroll::-webkit-scrollbar {
  width: 5px;
  position: relative;
  left: 5%;
  height: 5px;
}

.convScroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 126, 195, 0.2);
}

// .convScroll::-webkit-scrollbar-track {
//     background-color: #eee;
//     border-radius: 8px;
// }

.input-white-bg {
  background-color: white !important;
}

.assignBg {
  background-color: #f0f2f5;
  height: 89vh;
  border-radius: 4px;
}

.accessDeniedMsg {
  font-size: 20px;
  position: relative;
  bottom: 4px;
}

.memberWidth {
  width: 165px;
}

@media only screen and (min-width: 1024px) {

  .contact-members-sales,
  .contact-members-support {
    display: flex;
  }

  .contact-members-sales {
    position: relative;
    // left: 40%;
  }

  .chatRow {
    padding: 0px 16px;
    height: 91.5vh;
  }
}

.memberAssign {
  background: #fff7f4;
  padding: 3px;
  border-radius: 4px;
  display: inline-block;
}

.memberName {
  border-radius: 2px;
  border: 0.5px solid #e2e2e2;
  background: #f0f9fc;
  color: #5f5f5f;
  font-size: 10px;
  padding: 2px 3px 3px 3px;
  border-radius: 25px;
}

.textmessage {
  font-size: 13px;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.memberselected {
  border-radius: 2px;
  border: 0.5px solid #e2e2e2;
  background: white;
  // font-weight: 900;
  font-size: 10px;
  color: rgb(133, 131, 131);
  padding: 3px;
  border-radius: 25px;
}

.conversationSideSection {
  margin-left: auto;

  .conversationTime,
  .conversationStatus {
    position: absolute;
    bottom: 19px;
    right: 0;
    font-size: 10.25px;
    color: #5d5d5d;
  }

  .conversationTime {
    top: 3px;
    text-align: right;
    padding-right: 8px;
  }

  .notHideTime {
    position: absolute;
    bottom: 19px;
    right: 0;
    font-size: 10.25px;
    color: #5d5d5d;
    top: 3px;
    text-align: right;
    padding-right: 8px;
  }

  .conversationStatus {
    margin-left: unset;
    position: relative;
    top: 20px;
    color: #f35c5c;

    &.success {
      color: #6ec77c;
    }

    .convStatusIcon {
      margin-left: 3px;
    }
  }
}

.assignStatus {
  color: #007ec3;
  border: none;
  border-radius: 4px;
  background: linear-gradient(0deg,
      rgba(0, 126, 195, 0.1) 0%,
      rgba(0, 126, 195, 0.1) 100%),
    #fff;
  width: 150px;
  font-size: 14.5px;
  height: 95%;

  &:hover {
    color: #007ec3;
    border: none;
    border-radius: 4px;
    background: linear-gradient(0deg,
        rgba(0, 126, 195, 0.1) 0%,
        rgba(0, 126, 195, 0.1) 100%),
      #fff;
    width: 150px;
    font-size: 14.5px;
    box-shadow: none;
  }
}

.show>.btn-secondary.dropdown-toggle {
  &:focus {
    box-shadow: none;
    color: #007ec3;
    border: none;
    border-radius: 4px;
    background: linear-gradient(0deg,
        rgba(0, 126, 195, 0.1) 0%,
        rgba(0, 126, 195, 0.1) 100%),
      #fff;
    width: 150px;
    font-size: 14.5px;
  }
}

.custom-caret::after {
  margin-right: 20px;
}

.dropdown {
  .btn-secondary {
    &:focus {
      color: #007ec3;
      background: linear-gradient(0deg,
          rgba(0, 126, 195, 0.1) 0%,
          rgba(0, 126, 195, 0.1) 100%),
        #fff;
      border: none;
      box-shadow: none;
    }

    &:active {
      color: #007ec3;
      background: linear-gradient(0deg,
          rgba(0, 126, 195, 0.1) 0%,
          rgba(0, 126, 195, 0.1) 100%),
        #fff;
      border: none;
      box-shadow: none;
    }
  }
}

.imgUploadHeight {
  height: 77.5vh;
  overflow-y: hidden;
}

.colWidth {
  max-width: 67%;
  position: relative;
}

.convUploadPreview {
  height: 60%;
  width: 60%;
  background-color: #fff;
  padding: 7px;
  border-radius: 4px;
  text-align: center;

  .uploadConvImg {
    height: 100%;
  }
}

.contactFont {
  font-size: 14px;
  font-weight: 400;
}

.contactNamestrt {
  margin-top: 1px !important;
  margin-bottom: 2.5px !important;
}

.messageFont {
  color: #5f5f5f;
  margin-top: -4px;
  display: flex;

  .reminderMsg {
    width: 225px;
    display: block;
    color: #744210;
    background-color: #fefcbf;
    line-height: 1.2;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#pinConversationTooltip {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notesBg {
  background-color: rgb(255, 248, 213);
  margin-top: 1%;
  margin-left: 3%;
  white-space: nowrap;
  font-size: 12.5px;
  border-radius: 4px;
  padding: 0px 1px;

  &:focus {
    background-color: rgb(255, 248, 213);
    margin-top: 7%;
    margin-left: 3%;
    white-space: nowrap;
    font-size: 12.5px;
    border-radius: 4px;
    padding: 0px 1px;
  }
}

.notesBgsent {
  background-color: rgb(255, 248, 213);

  &:focus {
    background-color: rgb(255, 248, 213);
  }
}

.noteTemplateFont {
  color: #007ec3;
}

.templateBg {
  background: transparent;
}

.tempWidth {
  width: 35%;
}

.quickRepScroll {
  max-height: 40vh;
  overflow-y: auto;
  padding-right: 10px;
}

.quickRep {
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: #fbfdff;
    padding: 5px;
  }
}

.disabledStyle {
  cursor: pointer;
  background-color: #e9ecef;
  color: #adb5bd;
  outline: none;
}

.converInput:focus {
  outline: none;
}

.convoUploadPopup {
  .convoUploadPopup_item {
    cursor: pointer;
    padding: 10px 16px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.hover-red:hover svg {
      color: #fb5075;
    }

    &.hover-blue:hover svg {
      color: #1977f2;
    }

    &.hover-purple:hover svg {
      color: #8575f4;
    }
  }
}

#toolbar {
  border: none;
}

#toolbar button {
  background: none;
  border: none;
  cursor: pointer;
}

#toolbar button:focus {
  outline: none;
}

#toolbar button svg {
  width: 16px;
  height: 16px;
}

.ql-container {
  border: none !important;
  font-size: 0.81568rem;
}

.noteTemplate {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  bottom: 8px;
}

.quill-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.quillEditor {
  width: 41vw;
  word-wrap: break-word;
  max-width: 700px;
  overflow-y: hidden !important;
}

button.ql-bold:disabled,
button.ql-italic:disabled,
button.ql-strike:disabled {
  pointer-events: none;
  cursor: default;
}

.createContactBtn {
  margin-left: auto;
  padding: 5px;
  margin-bottom: 7px;
}

.changeBorderColor {
  border: 1px solid #007ec3;
}

.filterList {
  display: flex;
  //padding: 10px 0px 10px 0px;
  cursor: pointer;
}

.contactBorder:hover {
  background: #fbfdff;
  border-radius: 4px;
}

.filterScroll {
  max-height: 30vh;
  overflow-y: scroll;
  padding-right: 13px;
}

.listButtons {
  color: #0e71c3;
  font-size: 14px;
}

.form-check-input {
  position: static !important;
}

.ql-snow.ql-toolbar {
  padding: 0px 5px 2px 10px !important;
  border: none !important;
}

.initiateButton {
  width: 34% !important;
  margin-top: 10px;
}

.statusDropdown .assignStatus.custom-caret {
  width: 85% !important;
  height: 90% !important;
}

.statusDropdown .assignStatus.custom-caret:hover,
.statusDropdown .assignStatus.custom-caret:focus {
  width: 85% !important;
  height: 90% !important;
}

.statusDropdown .dropdown-menu {
  transform: translate(0px, 43px) !important;
}

.hide-quill-placeholder .ql-editor::before {
  opacity: 0;
  /* Hide the placeholder */
}

.messageColumn {
  position: relative;
  right: 1%;
  bottom: 1px;
}

// .chatRow {
//     padding: 0px 16px;
// }

.messageCardWidth {
  width: 103.5%;
  height: 100%;
}

.Conversation-Container {
  position: relative;
  left: -0.7%;
  top: 3px;
}

.botName {
  position: relative;
  left: -12px;
  top: 1px;
  color: white;
}

.botnameconv {
  position: relative;
  left: -13px;
  top: 1px;
  font-size: 11px;
  color: white;
}

.marginbot {
  margin-left: -5px;
}

.single-line-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 715px;
  max-width: 1000px;
  align-items: left;
  background-color: black;
  margin: 0px 2px 0px 0px;
  padding: 0px;
}

.roundMember {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #6ea9b6;
  color: white;
  font-size: 7px;
  margin-right: 3px;
  margin-left: 1px;
  padding-top: 1px;
  // font-weight: 400;
}

.custom-option {
  cursor: pointer;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
}

.custom-option:hover {
  background-color: #f0f0e8;
}

.custom-option.selected {
  background-color: #0e7eb33d;
  color: #f0f0e8;
}

.textMsgLength {
  max-width: 44%;
  margin-right: 10px;
}

.chatloader {
  position: absolute;
  left: 3px;
  bottom: 10px;
  // border: 1px solid gainsboro;
  width: 98%;
  height: 4%;
  color: #5d89bb;
  // background-color: #5289c0;
  padding-left: 40%;
  // padding-top: 1%;
  z-index: 999;
}

.loader {
  width: 20px;
  height: 20px;
  border: 5px dotted #5d89bb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.sourceIcons {
  margin-top: 7px;
  margin-right: 7px;
  margin-bottom: 5px;
}

.convBackground {
  background-color: #EFFFDF;
  padding: 0px 5px;
  border-radius: 10px 0px 10px 10px;
}

.replyMsgBg {
  background: #f0ebeb;
  padding: 5px;
  border-left: 2.5px solid #b2afaf;
  margin-bottom: 5px;
  border-radius: 4px;
}

.assign {
  color: #908e8e;
  font-weight: 600;

  .convOverlayLoading {
    position: fixed;
    left: 50vw;

    .convOverlayLoading__wrapper {
      margin-top: 25px;
      background-color: #fafafa;
      border: 1px solid #efefef;
      padding: 12px;
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}

.flowMsg {
  background: #EFFFDF;
  padding: 5px;
  border-radius: 4px;
  color: gray;
}

.contactsHeight {
  height: 60vh;
}

.audioMsg {
  width: 307px;
  margin: 7px 3px 0px 3px;
}

.audioImg {
  position: relative;
  right: 5px;
}

.itemsColor {
  color: rgb(0 126 190) !important;
}

.reminderName {
  background-color: #6ea9b6;
  color: white;
  border-radius: 50%;
  padding: 1px 5px;
  font-size: 10px;
  font-size: 9px;
  margin-left: 4px;
}

.reminderClock {
  margin-top: 3%;
  margin-right: 2%;
  color: #c29f45;
}

.visitedSite {
  color: #004b74;
  font-weight: 500;
}

.timeTopMargin {
  margin-top: 14px;
}

.paymentMessage {
  .gray {
    background: #ebebeb;
    border-radius: 10px;
    padding: 4px;

    .line {
      color: #d1cece;
      white-space: nowrap;
      overflow: hidden;
    }

    .total {
      font-family: "Noto Sans", sans-serif !important;
      font-weight: 650;
    }
  }

  .pay {
    color: #007ec3;
    text-align: center;
    font-weight: 600;
    margin-top: 2%;
  }
}

.unsupportedText {
  font-size: 11px;
  color: rgb(133, 130, 130);
  font-style: italic;
  margin-right: 0.5rem;
}

.errMsgReason {
  position: absolute;
  right: 100%;
  font-size: 16px;
  color: #e53e3e;
  margin-top: 10px;
  cursor: default;
}

.unSupportedMsgReason {
  // position: absolute;
  // left: 100%;
  font-size: 16px;
  color: #e53e3e;
  margin-top: 10px;
  cursor: default;
  margin-left: 0.25rem;
}

.contact-err {
  // position: absolute;
  // margin-left: 3rem;
  // right: 80%;
  // top: -0.1%;
  font-size: 11px;
  color: #e53e3e;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nofont {
  font-family: "Noto Sans", sans-serif !important;
}

.downloadBtn {
  margin-left: 423px;
  margin-bottom: 5px;
  color: #413f3f;
}

.downloadBtn:hover {
  margin-left: 423px;
  color: #274df7;
}

.loadertxt {
  margin-left: 340px;
}

.adjustCaption {
  margin-top: 5px;
  margin-left: 5px;
  width: 281px;
}

.alignCaptionInput {
  display: flex;
  width: 75%;
  position: relative;
  top: 5%;
  left: 4%;
}

.loadertxt {
  margin-left: 340px;
}

.selected_business_catalogue {
  .business_cart {
    display: flex;
    background: #ebebeb;
    border-radius: 4px;
    padding: 4px;

    .cart_img {
      img {
        height: 3rem;
        width: 3.5rem;
      }
    }

    .cart_items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .view {
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    color: #007ec3;
    font-weight: 600;
    cursor: pointer;
  }
}

.adjustContacts {
  margin-left: 5px;
}

.componentMsgIcon {
  margin-top: 6.5px;
}

.uploadFiles {
  background: #007ec3;
  border-radius: 4px;
  padding: 5px 15px 5px 5px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.deletedContact {
  padding: 18px 0;
  font-weight: 550;
  font-size: 14px;
  //color: #dc3545;
}

.labelSize {
  font-size: 13px;
  font-weight: 500;
  color: #5f5f5f;
  margin: 2px;
}

.editor-footer {
  padding: 12px;
  position: sticky;
  display: flex;
  justify-content: right;
  background-color: #efefef;
  bottom: 0;
  right: 0;
  margin-left: -12px;
  width: 27.5vw !important;
  width: 100%;
  // z-index: 1200;
}

.advancecard {
  width: 100%;
  margin-top: 7%;
  border: 1px solid #dddfe0;
  border-radius: 8px;
}

.badgelabel {
  background-color: #f0f0f0;
  padding: 1px 5px;
  border-radius: 5px;
}

.notFound {
  text-align: center;
}

.advanceValue {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Noto Sans", sans-serif !important;
}

.emjPop {
  // margin-bottom: 12%;
  position: relative;
  right: 20px;
  height: 100px !important;
}

.orderRow {
  margin-left: -23px;
}

#filterDisable {
  pointer-events: none;
  opacity: 0.4;
}

#filterAble {
  pointer-events: all;
  opacity: 1;
}

#noPadding>div>div>div>div.conversationReport_container__xfWCb>div>div>div.react-bs-table.react-bs-table-bordered>div.react-bs-container-header.table-header-wrapper>table>thead>tr>th:nth-child(1)>input {
  display: none !important;
}

//   #formMultiSelect{
//     width: 16px;
//     height: 16px;
//     background-color: #fff;
//     border: 2px solid #bfbfbf;
//     border-radius: 4px;
//     margin-right: 10px;
//     display: inline-block;
//     vertical-align: middle;
//     transition: background-color 0.2s ease-in-out;
//     // margin-left: 1px;
//   }

body>div.conversationFilter_popup__fnAdj.popover.bs-popover-end>form>div.conversationFilter_body__94Esk.popover-body>div:nth-child(3) {

  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    margin-left: 4px;
    margin-bottom: 6px;
  }
}

.thick-icon {
  transform: scale(1.1);
  /* Scale up the icon to make it thicker */
  color: white;
  /* Example color */
}

/* Optional: If working with SVG strokes, not typically applicable for FontAwesome */
.thick-icon svg {
  stroke: white;
  stroke-width: 10px;
}

#filterAble {
  z-index: inherit;
}

.Conversation-Container {
  .react-bs-select-all {
    display: none !important;
  }
}

.cancelledButton {
  color: rgb(216, 50, 50);
  background-color: rgb(241, 223, 223);
  border-radius: 25px;
  border: 1px rgb(216, 50, 50);
  font-size: 14px;
  padding: 4px 6px;
}

.inviteBtn {
  color: #007ec3;
  text-align: center;
  font-weight: 600;
  margin-top: 2%;
  padding-left: 5px;
}

.contacticon {
  color: #e4dede;
}


.conv-filter-btn {
  border-radius: 4px;
  border: 1px solid var(--gray-400, #dcdfe3) !important;
  background: transparent !important;
  padding: 10px;
  cursor: pointer;
  height: 10%;
}

.compMsgWidth {
  width: 97%;
  margin-left: 3.5px;
}

.termsAndCon {
  color: #007EC3;
  padding-left: 5px;
  font-weight: 500;

  &:hover {
    color: #007EC3;
    padding-left: 5px;
    font-weight: 500;
    text-decoration: underline;
  }
}

.zohoCard {
  height: 92%;
}

.retryErrMMsg {
  background: #17a2b8;
  color: #fff;
  padding: 4px;
  margin-top: 2px;
  border-radius: 50%;
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.retryMsgLeftPanel {
  margin-left: 4px;
  margin-top: 8px;
  font-size: 11px;
  color: #17a2b8;
}

.triggerScroll {
  overflow-y: auto;
  height: 300px;

  .inFlex {
    display: inline-flex;
  }

  .fontBig {
    font-size: 14px;
  }

  .fontSmall {
    font-size: 12px;
  }

  .noDataFound {
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 300px;
  }
}

.triggerButton {
  width: 130px !important;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-left: 10px;
}

.cancelledButton {
  width: 80px;
  height: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.accountSuspendedPayment {
  margin-left: 10px !important;
  padding: 3px !important;
  position: relative;
  bottom: 4px;
}

.accSuspension {
  position: relative;
  left: -0.7%;
  top: -6px;
}

.integrationIconForChat {
  position: relative;
  top: 4px;
  margin: 2.2px;
}

.ctwaBadge {
  background: #a1d0fb;
  padding: 3px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 9px;
}

.bulkBot {
  position: relative;
  right: 4px;
}

.contactFont {
  font-size: 14px;
}

.lastNoteName {
  position: relative;
  top: 20px;
}

.assignAlert {
  left: 62%;
  transform: translateX(-50%);
  background: none !important;
}

.auto_symbol {
  color: #73848a;
  font-weight: 900;
  font-size: 12px;
  padding-left: 2px;
}

.msgWithSpace {
  white-space: pre-wrap;
}

.convNotesEdited {
  font-size: 10px;
  padding-top: 3px;
  color: #6c6161;
  float: right;
  font-weight: 600;
}

.marginDown {
  margin-top: 3px;
}