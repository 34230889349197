.rightArrow {
  width: 16px;
  cursor: pointer;
  margin-right: 15px;
  margin-left: 10px;
  font-size: 20px;
  float: right;
}


.sidebar {
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden !important;
  /* Adjust the height as per your requirements */
}

table {
  width: 100%;
  table-layout: fixed;
  margin-bottom: 0px;
}

th,
td {
  text-align: center;
  // border-bottom: 12px solid black;
}

table,
.table-striped,
.table-bordered td,
.table-hover,
.table-bordered th {
  // border-bottom: 1px solid #dadada;
  border-right: none;
  border-left: none;
  background-color: white;
  margin-bottom: 0px;
}

#pageDropDown {
  background: white;
  color: black;
  border: 1px solid #dddddd;

  &:active {
    border: 1px solid #dddddd;
  }
}

.page-item a {
  background-color: white !important;
  color: black;
  display: flex;

  &:hover {
    background-color: white !important;
  }
}

.page-item.active a {
  background-color: #00B598 !important;
  color: white;
  border-color: #00B598 !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
    background-color: #458d80 !important;
  }

  &:focus {
    background: #458d80;
    border: #458d80;
    box-shadow: none !important;
  }
}

// .react-bs-select-all {
//   display: none;
//   border: none;
// }

.addCancelBtn {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.error_alert {
  line-height: 20px;
}

.sidebar-content {
  padding: 10px;
  /* Additional styles for the content within the sidebar */
}

.redstar_required:after {
  margin-left: 0.5px;
  margin-top: 5px;
  content: " *";
  color: red;
  font-size: 12px;
}

/* Styling the scrollbar */
.sidebar::-webkit-scrollbar {
  width: 4px;
  /* Adjust the width as per your preference */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.modalsidebar {
  overflow-y: scroll;
  height: 300px;
}

.modalsidebar::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width as per your preference */
}

.modalsidebar {
  overflow-y: scroll;
  height: 300px;
}

.modalsidebar::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width as per your preference */
}

.whatsappImg {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.import,
.export {
  background-color: #e5f6ff;
  font-size: 14px;

  svg {
    margin-bottom: 5px;

  }
}

.clrSel {
  background-color: #e5f6ff;
  // margin-bottom: 10px;
  // margin-top: 5px;
  // margin-right: 5px;
  margin: 10px;
  font-size: 14px;
}

.DelBtn {
  margin-bottom: 14px;
}

.labelColor {
  color: black;
  margin-top: 10px;
  font-size: 12px;
}

// .EditCancel {
//   background-color: #fbf8f8;
//   border: 1px solid #dcdfe3;
//   margin-left: 45px;
//   font-size: 14px;
// }

// .EditCancelMember {
//   background-color: #fbf8f8;
//   border: 1px solid #dcdfe3;
//   margin-right: 10px;
//   font-size: 14px;
// }

// .AddCancel {
//   display: flex;
//   justify-content: end;
//   margin-top: 10px;
// }

.ActionField {
  display: flex;
  justify-content: space-evenly;
}

//------------INVITE--------

.invite {
  width: 500px;
}

.forget-password {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

.inviteTitle {
  margin: auto;
  text-align: center;
}

.WhatsAppOpt {
  display: flex;
}

.inputBorder {
  border: 1px solid #ededf5;
  border-radius: 5px;
}

.addTag {
  background-color: #007ec3 !important;
  width: 100%;
  color: white;
  font-size: 14px;
  box-shadow: none !important;

  &:hover {
    color: white;
  }
}

//-------------Center Form--------------
.centered-form-temp {
  display: flex;
  flex-direction: column;
  width: 80%;
  // margin: 15px;
  margin: auto;
  position: relative;
  bottom: 2%;
  height: 85%;
}

.headerContainer {
  // margin: auto;
  // margin: ;
  // display: flex;
  // width: 80%;
  // justify-content: space-between;

  // span{
  //   margin-left:10px;
  // }
  position: sticky;
  top: 0;
  background-color: white;
  padding: 3% 5%;
  z-index: 1000;
}

//--------------switchButton-------------
.switch-buttons {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #E7E7E7;
  border-radius: 15px;
  cursor: pointer;
  margin: 0px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 3px;
}

.switch-sliders {
  position: absolute;
  top: 1.5px;
  left: 2px;
  width: 17px;
  height: 16px;
  border-radius: 50%;
  background-color: #007ec3;
  transition: transform 0.3s;
  margin: 1px;
}

.switch-buttons.checked .switch-sliders {
  transform: translateX(19px);
  background-color: #007EC3 !important;
}

.switch-slider.disabled-slider {
  pointer-events: none;
  opacity: 0.4;
}

.switch-button-auto {
  position: relative;
  display: inline-block;
  width: 71px;
  height: 24px;
  background-color: #007EC3;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 3px;
  padding-top: 1%;
}

.switchdisable {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
  background-color: #83a8ce;
  border-radius: 15px;
  cursor: pointer;
  margin: "0px";
  box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 3px;
  padding-top: 1%;
}

.switchdisable-auto {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 24px;
  background-color: #007EC3;
  border-radius: 15px;
  cursor: pointer;
  margin: "0px";
  box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 3px;
  padding-top: 1%;
}

.switch-slider-auto {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s;
  margin: 1px;
}

.switch-button-auto.checked .switch-slider-auto {
  transform: translateX(45px);
  background-color: #fff !important;
}

.textwidth {
  font-size: 9.7px;
  font-weight: 600;
  color: #fff;
}

.marginleft {
  margin-left: 30%;
}

.marginleft-config {
  margin-left: 35%;
}

.lowercase {
  text-transform: lowercase;
}

.addTagSelect {
  margin-top: 4px;
}

// Tags
.tag-list {
  background-color: #EFF4FA;
  color: black;
  word-spacing: 3px;
  margin: 4px;
  padding: 2px 6px;
  border-radius: 2px;
  // box-shadow: 2px 3px 4px rgba(19, 18, 18, 0.817);
  cursor: default;

  span {
    cursor: pointer;
  }
}

.btn-close {
  display: none;
}

.tag-list-view {
  color: black !important;
  background-color: #EFF4FA;
  color: white;
  word-spacing: 3px;
  padding: 0px 6px;
  border-radius: 2px;
  // box-shadow: 2px 3px 4px rgba(19, 18, 18, 0.817);
  cursor: default;
}

.tag-count {
  right: 10px;
  top: 5px;
}

.tag-dropdown-content {
  position: absolute;
  right: 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 2px rgb(178, 174, 174);
  text-align: center;
  overflow: auto;
  /* Use 'auto' for scrolling when needed */
  max-height: 200px;
  /* Set a maximum height for the dropdown */
  width: 50%;
  float: right;
  border-radius: 5px;
  overflow-x: hidden;
  padding: 5px auto;
  max-height: 10%;
}

.drop-item {
  background-color: #EFF4FA;
  display: flex;
  justify-content: space-between;
  margin: 4px auto;
  border-radius: 5px;
  color: black;
  cursor: default;
}


.faTags {
  align-items: center;
  color: #007ec3;
  font-size: 1rem;
  margin-left: 10px;
}

.faTagsCount {
  color: #007ec3;
  font-weight: 500;
  align-items: center;
  margin-left: 3px;
}

.dropTag {
  font-size: 16px;
  cursor: pointer;
}

.tagsIcon:hover {
  // background-color: #8bc0dac7;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  padding: 0px;
}

.email-data {
  word-wrap: break-word;
}


// .cancelBtn {
//   background: #DCDFE3;
//   border: #DCDFE3;
//   margin-top: 10px;
//   box-shadow: none;
//   font-size: 14px;

//   &:hover {
//     box-shadow: none;
//   }

//   &:focus {
//     box-shadow: none;
//   }
// }
//-*---------------*--  SEARCH CONTACT && MEMBER-*---------------*-//
.contact-container {
  .react-bs-table-container {
    .react-bs-table-tool-bar {
      .row {
        display: block !important;
      }
    }
  }

  .global-top-table-button {
    float: right;
    position: relative;
    top: 25px;

    .contact-filter-btn {
      width: 200px;
    }

  }

  .global-table-title {
    margin-top: 2%;

    h5 {
      margin: 0px;
    }
  }
}

.member-container {
  .global-top-table-button {
    float: right;

    button {
      padding: 10px;
    }
  }

  .global-table-title {
    margin-top: 2%;

    h5 {
      margin: 0px;
    }
  }
}


.inlinetag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.radiolabel {
  position: relative;
  top: 2px;
}

.Community_Container {
  margin-top: 1em;

  .tab-content {
    margin-top: -1px;
    background: #fff;
    border: 1px solid #c8ced3;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .nav-tabs .nav-link.active {
    color: #049fdb;
    padding: 0px;
    border: 1px solid #c8ced3;
    border-bottom: none;
  }

  .nav-tabs {
    padding: 0px;
  }

  .nav-item {
    padding: 0px;
    position: relative;
    right: 0.3%;
  }
}

.selectedCount {
  position: relative;
  top: 70px;
  left: 2px;
  font-size: 14px;
}

.contactTable {
  margin-top: -1%;

  .react-bs-table-tool-bar .form-group {
    position: relative;
    top: -25px;
  }
}

.contactToConv {
  position: relative;
  right: 7%;
}

.waring-alert {
  padding: 5px;
  background-color: #FFF5DC;
  color: #A88300;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  margin: 0px;
  border-radius: 5px;
}

.popup {
  .cancelButton {
    width: 60px;
  }
}

.filterIcon {
  border: 1px solid var(--gray-400, #dcdfe3) !important;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  // z-index: 1;
}

.filterIcon-active {
  background-color: #f5f5f5;
}

@keyframes moveLeftAnimation {
  from {
    margin-left: -100px;
    /* Start position */
  }

  to {
    margin-left: 0;
    /* End position */
  }
}


.contact-filter-container {
  // background-color: #FBFBFB;
  // border-right: 1px solid #BBBBBB;
  // border-top: 1px solid #BBBBBB;
  // // padding: 0px 20px;
  // border-radius: 0px 5px 0px 0px;
  // height: 36em;
  background-color: #FBFBFB;
  border-right: 1px solid #d7cece;
  border-top: 1px solid #e0dcdc;
  /* border-radius: 0px 5px; */
  height: 38em;
}

.move-left {
  animation: mymove 5s ease;
  animation-name: moveLeftAnimation;
  /* Apply animation */
  animation-duration: .10s;
  /* Animation duration */
  animation-timing-function: ease;
  /* Animation timing function */
  animation-fill-mode: forwards;
  /* Maintain final state after animation */
}

.footer-in-filter {
  position: sticky;
  bottom: 2.5rem;
}

.filter-form {
  padding: 10px;
  max-height: 408px;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
}

.excelLoading {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  overflow: hidden !important;
  width: 148px;
  padding: 8px 12px;
  outline: none !important;
}

// add tag

.tagContainer-AddTag {
  input[type="checkbox"] {
    accent-color: #009980;
  }
}

.disableCreateComBtn {
  opacity: 0.4;
  cursor: pointer;
}


.info_time_line_container {
  z-index: 99999;
  // margin-bottom: 15px;
  position: sticky;
  top: 40px;
  background: white;
  padding: 10px;

  .info,
  .time_line {
    background: none !important;
    border: none;
  }

  .active {
    /* Correct way to style if element has 'area-selected' class */
    border-bottom: 2px solid #0083ff !important;
  }
}

.time_line_container {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  scrollbar-width: auto;
  padding: 10px;

  h6 {
    color: #4a5568;
  }

  .timeline_date {
    padding: 7px;
    background-color: #f7fafc;
  }

  .action_field {
    text-transform: capitalize;

    .action_field_type {
      text-transform: lowercase;

      .action_field_type_contact {
        text-transform: capitalize;
      }
    }
  }

  .created_time {
    text-transform: lowercase;
  }

  .text-by {
    font-size: 11px;
    font-weight: 600;
  }
}

.contact_form_container {
  // overflow-y: scroll !important;
  margin-top: 1rem;
  scrollbar-width: auto;

}

.btn-connected:focus,
.btn-connected:active {
  box-shadow: none !important;
  background-color: #458D80 !important;
  border: #458D80;
  color: white;
}

.btn-connected:hover .icon-desc {
  color: #fff !important;
}