@import "../../../src/variable.scss";

.customToast{
    border-radius: 12px;
    background: $light-theme-background;
    border: 1px solid $dark-theme-background;

    .toast-content{
        font-weight: 500;
    }
}