.calenderInput {
    width: 75%;
    margin-right: 10px;
    cursor: pointer;

    i {
        color: #4A4A4A;
        margin-right: 10px;
    }

    &.disabled {
        cursor: not-allowed;
        background-color: #efefef;
    }
}

.selectedContact {
    max-width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    padding: 5px 12px;
    background-color: #fafafa;
    margin: 7px 0;
    margin-bottom: 18px;

    .data {
        .name {
            margin-left: 5px;
            font-size: 0.8rem;
            font-weight: 500;
        }
    }
}

.closeLabel {
    margin-bottom: 0 !important; 
    margin-top: 10px;
}
.mycard{
    background-color: #458D80;
}

.textareaStatus {
    font-size: .8rem;
    font-weight: 400;
    color: #A3A3A3;
    text-align: right;
    padding: 1px 3px;
  }
  
  