
.analytics-dashboard {
  padding-top: 1rem;
  padding-bottom: 1rem;
  .date-filter {
    display: flex;
    gap: 1rem;
  }

  .conversation-dashboard {
    margin: 1rem 0;

    .dashboard-count {
      display: flex;
      gap: 1rem;

      .conversation-status-card {
        border-radius: 0.35rem;
        border: 1px solid rgb(224, 224, 224);
        width: 16rem;
        height: 6rem;
        background-color: white;

        .conversation-header {
          display: flex;
          justify-content: center;
          align-items: center;
          // margin-bottom: 0.25rem;
          margin-top: 0.5rem;
          font-size: 12.5px;
          color: #4d684a;

        }

        .conversation-count {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.75rem 2.5rem 1rem 2.5rem;
        }
      }
    }
  }
}

.nodatadiv {
  border: 1px solid rgb(231, 231, 231);
  height: 250px;
  width: 500px;
  padding: 20px 151px;
  margin: 15px 15px 15px 70px;
}

.convmargin {
  margin-left: 170px;

}

.convContent {
  margin-left: 245px;
}

.catmargin {
  margin-left: 130px;
}

.catContent {
  margin-left: 157px;
}

.statusMargin{
  margin-left: 177px;
}

.statusContent{
  margin-left: 254px;
}

.contactContent{
  margin-left: 244px;
}

.view-insights-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    display: block;

    .backBtn {
      color: black !important;
      border-radius: 50%;
      margin-right: 10px;
      outline: none;

      &:hover {
        transition: all 200ms ease;
        background-color: #efefef;
        cursor: pointer;
      }
    }
  }
}

.view-insights-body{

  .insights-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .form-group{
      display: flex;
      align-items: center;
      margin-bottom: 0;
      
      .channel-select{
        width: '12rem'
      }
  
      label{
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }

  .dashBoard{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

    .dashBoard-column{
      border: 1px solid rgb(224, 224, 224);
      width: 20vw;
      padding: 1rem;
      border-radius: 6px;
      color: #41465e;
      font-size: small;
    }
  }

  .insights-info {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: smaller;
    color: #718096;

    .insights-info-sub{
      color: black;
      margin-left: 0.5rem;
    }
  }

  .data-table{
    margin-top: 1rem;
    
    .thead-table{
      background: #eff4fa;

      tr{
        th{
          align-items: center;
          width: max-content;
        }
      }
    }
  }
}

.amount-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .amount-value{
    width: 2.5rem;
  }
}

.actionBars {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  min-height: 50px; 
}

.selectedColor {
  color: #007EC3;
}

.fixedPosition {
  position: fixed;
}

.retryMessages {
  position: relative;
  display: block;
  height: 170px;
}

/* Input field styling */
.react-time-picker__wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  transition: all 0.3s ease;
}

.react-time-picker__inputGroup__input {
  border: none;
  outline: none;
  width: 50px;
}

.label-select-container {
  display: inline-flex;
  align-items: center;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}


.leftAlign {
  margin-left: 0; /* Removes any extra margin to ensure it starts at the left edge */
  text-align: left; /* Ensures the text is left-aligned */
  width: 100%; /* Optional: Ensures the error message spans the width of the container */
}




