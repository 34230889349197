@import "../_variable.scss";

#card-v4 {
  background: white;
  border-radius: 1rem;
  padding: 1.1rem;
}

#card-v4 > div > div.d-flex.align-items-center.col-md-4 > div > span > button{
  &:hover{
    transition: background-color 0.3s ease, transform 0.3s ease;
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

// Base transition for all buttons
.dark-button,
.light-button,
.mixed-button {
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.dark-button {
  background: $dark-theme-background;
  width: fit-content;
  color: white;
  border: 1px solid $dark-theme-background;
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: 500;

  &:hover {
    background: darken($dark-theme-background, 10%);
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.light-button {
  background: white;
  width: fit-content;
  color: $dark-theme-background;
  border: 1px solid $dark-theme-background;
  padding: 0.5rem;
  border-radius: 8px;
  font-weight: 500;

  &:hover {
    // Lightening the dark theme background for a subtle highlight
    // background: lighten($dark-theme-background, 40%);
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.mixed-button {
  background: $light-theme-background;
  width: fit-content;
  color: $dark-theme-background;
  border: 1px solid $light-theme-background;
  padding: 0.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 500;

  &:hover {
    // Slightly darkening the light theme background on hover
    background: darken($light-theme-background, 5%);
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.app-dashboard {
  .exp-msg {
    font-size: 16px;
    font-weight: 500;
  }

  .hdr {
    font-size: 18px;
    font-weight: 600;
  }

  .planExpire,
  .subscribe-now {
    color: $dark-theme-background;
    margin-left: 0.25rem;
  }

  .subscribe-now {
    text-decoration: underline;
    cursor: pointer;
  }

  .welcome-msg {
    font-size: 18px;
    font-weight: 600;
  }

  .dashboard-intro {
    .userName {
      font-size: 16px;
    }

    .description {
      font-size: 16px;
      font-weight: 500;
    }

    .mrkt {
      display: flex;
      gap: 1rem;
      color: #5b5b5b;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;

      .info {
        svg {
          margin-right: 0.25rem;
        }
      }
    }
  }

  .profileStatus {
    font-size: 12px;
  }

  .explorePlans {
    .bg-explore {
      display: flex;
      justify-content: center;
      zoom: 0.6;
    }

    .exploreBody {
      text-align: center;
      .descp {
        padding: 0.5rem;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .dashboard-action {
    span {
      font-size: 12px;
      font-weight: 400;
    }

    .help-user {
      padding: 1.25rem;
      border-radius: 1rem;
      border: 2px solid #f0f0f0;

      .help-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .title {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .help-content {
        font-size: 14px;
        font-weight: 400;
      }

      &:hover {
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
      }
    }

    .feature {
      border: 2px solid #f0f0f0;
    }

    .additional-feature {
      border: 1px solid rgba(75, 75, 75, 0.5);
    }

    .feature,
    .additional-feature {
      padding: 1rem;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
      }

      .feature-title {
        font-size: 18px;
        font-weight: 500;
      }

      .feature-description {
        color: #4b4b4b;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .faq {
    .faq-header {
      font-size: 18px;
      font-weight: 500;
    }

    .question {
      &:hover {
        color: blue;
        text-decoration: underline;
      }
    }
  }

  .yourChannelQR {
    .channelQR-desc {
      font-size: 12px;
      font-weight: 400;
    }

    .your-channel-link {
      a {
        font-size: 12px;
      }
    }

    .your-channel-action {
      display: flex;
      justify-content: space-evenly;
      .your-action {
        padding: 0.5rem;
        border-radius: 8px;
        border: 1px solid rgba(75, 75, 75, 0.5);
        cursor: pointer;
      }
    }
  }

  .messageCredits {
    .bg-credits {
      display: flex;
      justify-content: center;
      // zoom: 0.7;
    }

    .creditsBody {
      text-align: center;

      .av-header {
        font-weight: 400;
        font-size: 12px;
        color: #4b4b4b;
      }

      .available-credits {
        font-size: 24px;
        font-weight: 600;
        margin-left: 0.5rem;
      }
    }
  }

  .mobile-app-banner {
    .mb-msg {
      font-size: 18px;
      font-weight: 500;
    }
    .mobile-app-description {
      font-size: 16px;
      font-weight: 400;
    }

    .mb-banner {
      display: flex;
      gap: 0.5rem;
    }
  }

  .referalComponent {
    .referral-hd {
      font-weight: 500;
      font-size: 14px;
    }

    .referral-desc {
      font-weight: 400;
      font-size: 12px;
      color: $light-text-color;
    }

    .referralCode {
      font-weight: 500;
      font-size: 12px;
      color: $light-text-color;
    }

    .r-code {
      color: $dark-theme-background;
      padding: 8px 40px;
      border-radius: 1rem;
      border: 2px dashed $dark-theme-background;
      font-weight: 500;
      font-size: 12px;
      width: fit-content;
      cursor: pointer;
    }
  }

  .integration-card {
    .int-header {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid #e9e9e9;
    }

    .available-apps {
      height: 7.25rem;
      overflow-y: scroll;
      overflow-x: hidden;
      .app-item {
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;

        .app-label {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

// .dashboard-container {
//   color: $primary-text-color;

//   .hello-user {
//     display: flex;
//     align-items: center;
//     font-size: x-large;
//     color: $secondary-text-color;
//     gap: 4px;
//   }

//   .whatapp-api-status,
//   .current-plan {
//     display: flex;
//     justify-content: end;
//     align-items: center;
//     gap: 1rem;
//   }

//   .dashboard-header {
//     .business-description {
//       padding: 1rem;
//       background: white;
//       border-radius: 6px;

//       .business-header {
//         padding: 1.5rem;
//         background: $card-primary-background;
//         border-radius: 6px;

//         .description {
//           .title {
//             color: $secondary-text-color;
//             font-weight: 800;
//             font-size: 18px;
//           }
//           .description-Add {
//             display: flex;
//             gap: 4px;
//             align-items: center;
//           }
//         }
//       }

//       .link-mobileNumber-decription {
//         margin-top: 1rem;
//         .link-mobileNumber-header {
//           color: $text-header-color;
//           font-size: 16px;
//           font-weight: 500;
//         }

//         .link-mobileNumber-decription-item {
//           display: flex;
//           align-items: center;
//           gap: 0.5rem;
//           margin-top: 0.5rem;

//           .star-icon {
//             size: 4px;
//           }

//           .know-more {
//             font-size: x-small;
//             color: $app-know-more-text;
//             cursor: pointer;
//           }
//         }
//       }
//     }

//     .message-credit-tab {
//       background: white;
//       border-radius: 6px;
//       height: 100%;
//       padding: 1.5rem;

//       .welcome-message {
//         font-size: 20px;
//         color: $secondary-text-color;
//         text-align: start;
//         padding: 0 0 0.75rem 0;
//       }

//       .wallet-section {
//         display: flex;

//         .credits-section {
//           margin-left: 1rem;
//           display: flex;
//           flex-direction: column;
//           justify-content: space-between; /* Puts space between top and bottom sections */
//           // height: 100%;

//           .credits-label {
//             color: $text-header-color;
//             font-size: 16px;
//           }

//           .credits-display {
//             display: flex;
//             gap: 2px;
//             color: $connect-btn-background;
//             font-size: 22px;
//           }
//         }
//       }
//     }
//   }

//   .whatsapp-conversation-credits {
//     padding: 2rem;
//     border-radius: 6px;
//     background: white;
//     margin: 0.25rem 0;
//     .whatsapp-conversation-credits-header {
//       color: $tertiary-text-color;
//       font-size: x-large;
//       text-align: center;
//       margin-bottom: 1rem;
//     }

//     .step-container {
//       display: flex;
//       align-items: center;

//       .step-progress {
//         .step-progress-icon {
//           display: flex;
//           justify-content: center;

//           #step-icon {
//             padding: 0.75rem;
//             border-radius: 50%;
//             width: fit-content;
//             margin-left: 0.5rem;
//           }
//           .step-icon-completed {
//             background: $secondary-text-color;
//           }
//           .step-icon-pending {
//             background: $text-header-color;
//             opacity: 50%;
//           }
//         }

//         .step-id {
//           font-size: x-small;
//           text-align: center;
//         }

//         .step-title {
//           color: $secondary-text-color;
//           text-align: center;
//           width: 6rem;
//           // width: 10rem;
//         }
//       }

//       .step-line-completed {
//         background: $secondary-text-color;
//       }

//       .step-line-pending {
//         background: $text-header-color;
//         opacity: 50%;
//       }
//       #step-line {
//         display: flex;
//         align-items: center;
//         width: 15rem;
//         height: 2px;
//       }
//     }

//     .step-title {
//       color: $secondary-text-color;
//       text-align: center;
//       width: 8rem;
//       position: relative;
//       left: -1.6rem;
//       margin-top: 0.5rem;
//       font-weight: 400;
//     }
//   }

//   .dashboard-planExplore {
//     margin: 0;
//     .planExplore-container-1, .planExplore-container-2 {
//       background: $card-primary-background;
//     }

//     .planExplore-container-3 {
//       background: $card-secondary-background;
//     }

//     #planExplore-container {
//       padding: 1.5rem;
//       border-radius: 6px;

//       .planExplore-header {
//         .planExplore-title-1, .planExplore-title-2 {
//           color: $secondary-text-color;
//         }

//         .planExplore-title-3 {
//           color: $tertiary-text-color;
//         }
//       }
//     }
//   }

//   .dashboard-footer {
//     .footer-header {
//       margin-bottom: 1rem;
//       .just-a-heads-up {
//         background: white;
//         padding: 2rem 2rem 2.5rem 2rem;
//         border-radius: 6px;
//         margin-bottom: 0.5rem;
//         .title {
//           color: $text-header-color;
//           font-size: 16px;
//           font-weight: 500;
//           margin-bottom: 0.75rem;
//         }

//         .description {
//           margin-bottom: 0.75rem;
//         }
//       }

//       .frequently-asked-questions {
//         background: white;
//         padding: 2rem 2rem 2.5rem 2rem;
//         border-radius: 6px;

//         .question-header {
//           color: $text-header-color;
//           font-size: 16px;
//           font-weight: 500;
//           margin-bottom: 0.75rem;
//         }

//         .questions {
//           .item {
//             display: flex;
//             gap: 1rem;
//             margin-bottom: 1rem;

//             .question {
//               text-decoration: underline;
//               color: $text-header-color;
//             }
//           }
//         }

//         .footer {
//           margin-top: 2rem;
//           color: $text-header-color;
//           font-size: 18px;
//           font-weight: 500;
//         }
//       }
//     }

//     .biz-feature {
//       background: white;
//       padding: 2rem 2rem 2.5rem 2rem;
//       border-radius: 6px;
//       margin-bottom: 0.5rem;
//       // cursor: pointer;

//       .link-generator {
//         background: $card-secondary-background;
//         padding: 0.5rem;
//         border-radius: 12px;
//       }

//       .footer-widgets {
//         background: $card-primary-background;
//         padding: 0.5rem;
//         border-radius: 12px;
//       }

//       .biz-feature-header {
//         color: $text-header-color;
//         font-size: 16px;
//         font-weight: 500;
//         margin-bottom: 0.75rem;
//       }

//       .bizQr-description {
//         font-size: 18px;
//       }

//       .biz-feature-referral-code{
//         display: flex;
//         font-size: small;
//         gap: 0.25rem;
//         align-items: center;
//         margin-top: 0.5rem;

//         .biz-feature-referral-code-value{
//           color: $secondary-text-color;
//           font-weight: 500;
//         }
//       }
//     }
//   }
// }
