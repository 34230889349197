.text-header-anchor {
  color: #4a91d4;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
    color: #4a91d4;
  }
}

.ticketTimeout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 41%;
  padding: 0.75rem 0.25rem;
  border: thin solid #bdbdbd;
  margin-bottom: 1rem;
  border-radius: 4px;
  margin-left: 4px;
  background: #fff;

  .ticketTimeout-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
  }

  .ticketTimeout-body {
    display: flex;

    .close-template {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#toolbar {
  button {
    cursor: default;
  }
}

.func-icons-people {
  padding: 0.2rem 0.35rem;
  border-radius: 16px;
  background: #ededed;
  margin-right: 4px;
}

.captionInput {
  width: 100%;
  margin-top: 1rem;
}

.cancel-btn {
  color: black;
  background: #fbf8f8;
  border: 1px solid #e2e2e2 !important;
  border-radius: 8px;
  cursor: pointer;
  width: 9rem;
  font-size: small;

  &:hover {
    background: #fbf8f8;
    color: black;
  }
}

.send-btn {
  color: white;
  background: #00b598;
  // border: 1px solid #e2e2e2 !important;
  border-radius: 8px;
  cursor: pointer;
  width: 9rem;
  font-size: small;

  &:hover {
    background: #00b598;
    color: white;
  }
}

.ticket-closed {
  position: fixed;
  bottom: 0;
}

.templateComponent {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(77, 73, 73);
  font-size: 11.5px;

  :hover {
    color: blue;
    text-decoration: underline;
  }

  :focus {
    color: blue;
    text-decoration: underline;
  }

  :active {
    color: blue;
    text-decoration: underline;
  }
}

.select-ticket {
  .select-ticket-header {
    font-size: 20px;
    font-weight: 500;
    height: 4.5rem;
    border-bottom: 1px solid #ededed;
    // border-right: 1px solid #ededed;
    padding: 1rem;

    // @media (max-width: 1280px) and (min-width: 768px) {
    //   .select-ticket-header{
    //     height: 4.5rem;
    //   }
    // }

    // @media (min-width: 1280px) and (max-width: 1400px){
    //   height: 17vh;
    // }

    .new-ticket {
      border: 1px solid #00b598;
      background: none;
      // border: none;
      width: 4rem;
      color: #00b598;
      // font-weight: 700;
      font-size: 14px;
      padding: 0.3rem 0.35rem;
      border-radius: 8px;

      &:hover {
        background: #00b598;
        color: white;
        border: none;
        border: 1px solid #00b598;
      }

      // margin-bottom: 1rem;
    }

    .filter-btn {
      border: 1px solid #ededed;
      padding: 3px 6px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background: #e5e5e54d;
      }
    }
  }

  .select-ticket-body {
    height: 82vh;
    // border-right: 1px solid #ededed;

    .functionalites {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ededed;
      width: 100%;

      .ticket-search {
        display: flex;
        align-items: center;
        color: #b1b3b7;

        input[type="text"],
        input[type="number"] {
          outline: none;
          border: none;
          padding: 8px 8px 8px 0;
          border-radius: 4px;
          width: 12.5rem;

          :focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }

      }


      .filter-search {
        padding: 0.35rem;
        border-radius: 1rem;
        cursor: pointer;

        &:hover {
          background: #ecf8f0;
          color: #00b598;

        }
      }
    }

    .tickets {
      height: 72vh;
      overflow-y: scroll;
      scrollbar-width: thin;
      height: 67vh;

      #selected {
        border-left: 3px solid #c5d4db;
        background-color: #f6fafe;
        border-radius: 4px;
      }

      .individual-ticket {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        width: 100%;
        padding: 0.25rem 0;
        border-bottom: 1px solid #ededed;
        cursor: pointer;

        &:hover {
          background: #f6fafe;
        }

        .avatarCenter {
          display: flex;
          justify-content: center;
          padding: 6px;
          position: relative;

          // .unread-badge {
          //   background: #f1592a;
          //   font-size: xx-small;
          //   font-weight: 500;
          //   height: fit-content;
          //   border-radius: 50%;
          //   color: white;
          //   padding: 2px 4px;
          //   position: relative;
          //   left: -10px;
          // }
        }

        .last-message {
          padding: 6px;

          .sender {
            font-size: 13px;
            font-weight: 500;
          }

          .contact-ticket-id {
            // border: 1px solid #5f5f5f;
            padding: 2px 6px;
            border-radius: 6px;
            margin-left: 0.5rem;
            font-size: x-small;
          }

          .last-message-text {
            font-size: 13px;
            color: #5f5f5f;
          }

          .last-message-subject {
            display: flex;
            font-size: smaller;
            color: #63686f;
          }
        }

        // .last-message-time {
        //   color: #63686f;
        //   font-size: 10px;
        //   width: 20%;
        //   display: flex;
        //   flex-direction: row-reverse;
        // }

        .last-message-time {
          color: #63686f;
          font-size: 10px;
          width: 20%;
          padding: 7px 5px 0px 10px;

          .overdueTime {
            color: #f16352e5;
            font-weight: 500;
          }
        }
      }
    }
  }
}

#quillEditor-notes {
  background: #fff8d5;
}

.quillEditor {
  width: 97%;
}

.send-ticket {
  .send-ticket-header {
    height: 4.5rem;
    border-bottom: 1px solid #ededed;
    // border-right: 1px solid #ededed;
    padding: 1rem 0.2rem;
    display: flex;
    justify-content: space-between;

    .ticket-header-title {
      border: 1px solid #5f5f5f;
      padding: 2px 8px;
      border-radius: 6px;
      margin-left: 0.5rem;
      height: fit-content;
      width: 4.75rem;
    }
  }

  .send-ticket-body {
    // border-left: 1px solid #ededed;
    // border-right: 1px solid #ededed;

    .ticketCreated {
      padding: 6px;
      z-index: 10;
      // position: relative;
      // display: flex;
      // justify-content: center;

      .ticket-created {
        border-radius: 6px;
        z-index: 10;
        padding: 8px;
        border: thin solid #bdbdbd;

        .ticket-created-header {
          display: flex;
          z-index: 10;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.2rem;

          .mail {
            display: flex;
            align-items: center;
            // margin: 0.7rem 0 1rem 0;

            .name {
              font-weight: 500;
              margin-right: 0.5rem;
              margin-left: 0.5rem;
              width: fit-content;
            }

            .mail-id {
              color: #63686f;
              display: flex;
              align-items: center;
            }

            .find-attachments {
              display: flex;
              font-size: smaller;
              background: #e6e6e6;
              border-radius: 6px;
              padding: 3px 6px;
              margin-left: 0.5rem;
              cursor: pointer;
            }
          }

          .mail-received {
            color: #63686f;
            font-size: small;
          }
        }

        .ticket-created-body {
          color: #63686f;
          font-size: small;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .conversation-item {
      display: flex;
      width: 100%;
      padding: 0.5rem 1rem;

      .message-avatar {
        z-index: 1;
      }

      .conversation-body {
        width: inherit;
        border-radius: 0 1rem 1rem 1rem;
        padding: 0.5rem 0.75rem 1rem 1rem;
        margin-left: 1rem;
        position: relative;

        .mention-symbol {
          position: absolute;
          top: -16px;
          left: -8px;
          font-size: 18px;
          color: gray;
          font-weight: 550;
        }

        .conversation-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .sender {
            font-weight: 500;
          }

          .sent-time {
            color: #63686f;
            font-size: x-small;
          }
        }

        .conversation-message {
          margin-top: 0.5rem;
        }
      }
    }

    .message-ticket {
      position: fixed;
      bottom: 0;
      width: 40%;
      margin-left: 1.2rem;
      // margin-bottom: 1rem;
    }
  }
}

.ticketDetails {
  .ticket-details-header {
    height: 4.5rem;
    border-bottom: 1px solid #ededed;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more-details {
      font-size: 14px;
      color: #63686f;
    }

    .ticket-details-functionalites {
      display: flex;
      gap: 0.5rem;

      .func-icons {
        padding: 0.5rem 0.55rem;
        border-radius: 3rem;
        // cursor: pointer;

        &:hover {
          background: #ededed;
        }
      }
    }
  }
}

.contactDetails {
  padding: 0 1.5rem 1.5rem 1.5rem;

  .contactDetails-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  .contactDetails-info {
    .field {
      margin-bottom: 1rem;

      .field-name {
        font-size: 12px;
        color: rgba(37, 41, 45, 0.5);
        font-weight: 400;
      }

      .field-value {
        font-weight: 400;
        font-size: 14px;
        color: #25292d;
      }
    }
  }

  .contact-details-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;

    .dltButton {
      background-color: #fbf8f8;
      border: 1px solid #e2e2e2 !important;
      font-size: 1em;
      color: #3b3e45 !important;
      border-radius: 4px;
      width: fit-content;
      padding: 8px 12px;
      margin-right: 0.5rem;
    }
  }
}

.filter-container {
  padding: 0.5rem 1.5rem;

  .clear-filter {
    border-bottom: 3px solid #00b598;
    color: #00b598;
    cursor: pointer;
    font-size: small;
    margin-right: 1rem;
  }

  .reset-filter {
    background: #00b598;
    color: white;
    cursor: pointer;
    font-size: small;
    padding: 4px;
    border-radius: 6px;
  }

  .filter-field {
    margin-bottom: 1rem;
  }

  .ticket-fields-filter {
    position: absolute;
    bottom: 0;
    width: 90%;

    button {
      background: #00b598;
      border: none;
      width: 100%;
      color: white;
      font-weight: 700;
      font-size: 14px;
      padding: 0.5rem;
      border-radius: 4px;
      margin-bottom: 1rem;
    }
  }
}

.ticketInput {
  border: 1px solid #ededed;
  border-radius: 4px;
  // width: 98%;
  margin-left: 10px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin-bottom: 0.5rem;
  background: white;

  .ticket-input {
    width: "max-content";

    .input-quill {
      max-height: 60vh;
      overflow: auto;
    }
  }

  // .ticket-input {
  //   width: 80px;
  //   word-wrap: break-word;
  // }

  .input-extras {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    z-index: 100;
  }
}

.timeoutTicket {
  text-align: center;
  border: 1px solid #abb5bd;
  padding: 20px 10px;
  // margin-top: 10px;
  border-radius: 4px;
  position: fixed;
  bottom: 0;
  width: 40%;
  // margin-left: 0.55rem;
  margin-bottom: 4px;
}

.ticketConvs {
  height: 38vh;
  overflow-y: auto;
  scrollbar-width: thin;
  height: 67vh;
  position: relative;
}

.vertical-line {
  width: 2px;
  background-color: #ccc;
  position: absolute;
  left: 32px;
  top: 0;
}

.mediaFiles {
  color: rgb(77, 73, 73);
  cursor: pointer;

  :hover {
    color: blue;
    text-decoration: underline;
  }

  :focus {
    color: blue;
    text-decoration: underline;
  }

  :active {
    color: blue;
    text-decoration: underline;
  }

  .caption {
    background: #f8f8f8;
    width: 100%;
    border-radius: 8px;
    padding: 6px;
    margin-top: 4px;
    cursor: text;
    color: rgb(77, 73, 73);
    text-decoration: none;
  }
}

.mediaFiles span:last-child {
  font-size: 11.5px;
}

.imageContainer {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ticketImg {
  color: #413f3f;
  position: relative;
  margin-left: 413px;
  bottom: 11px;
}

.imgName {
  position: relative;
  top: 10px;
}

.ticketPdf {
  height: 450px;
  width: 450px;
  overflow: auto;
}

.createTicketFont {
  font-size: 15px;
}

.noTickets {
  border-right: 1px solid #ededed;
  font-size: 15px;
}

.mini-filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  border-bottom: 1px solid #ededed;

  .mini-filter {
    display: flex;
    justify-content: center;
    // border: 1px solid #ededed;
    width: fit-content;
    padding: 0 8px;
    border-radius: 0.5rem;

    .filter-title {
      display: flex;
      border: 1px solid #ededed;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;

      // &:hover {
      //   background: #00b598;
      //   color: white;
      // }

    }

    .status-count-component {
      font-size: small;
      border: 1px solid #ededed;
      padding: 8px;
      width: max-content;
      display: flex;
      align-items: center;
      background: "#f6fafe";
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;

      .status-count {
        font-size: 12px;
        font-weight: 500;
        display: flex;
        justify-content: center;
      }

      .status-label {
        font-weight: 400;
        display: flex;
        justify-content: center;
        font-size: 13px;
        // color: #404244;
      }
    }
  }
}

///TYPE list component
.type-list-component {
  .type-list-header {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .type-list {
    max-height: 200px;
    overflow-y: auto;

    /* Change scrollbar color */
    &::-webkit-scrollbar {
      width: 3px;
      height: 10px;
      background-color: #f5f5f5;
      /* scrollbar background color */
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(198, 246, 213);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      /* scrollbar track color */
    }

    .type-list-item,
    .type-list-item-selected {
      padding: 5px 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 4px;
      font-size: 13px;
      margin-right: 6px;

      &:hover {
        background: rgb(198, 246, 213);
        color: #00b598;
      }
    }

    .type-list-item-selected {
      background: rgb(198, 246, 213);
      color: #00b598;
    }

    .type-count {
      background: #ededed;
      padding: 0.25rem 0.35rem;
      border-radius: 3rem;
    }
  }
}

#Open {
  color: #007EC3;
  background: rgb(198, 242, 246);
}

#Pending {
  color: #DFCD24;
  background: rgb(246, 242, 198);
}

#Resolved {
  color: #6EC77C;
  background: rgb(198, 246, 213);
}

#Closed {
  color: #A7A9B2;
  background: rgba(222, 230, 237, 0.922);
}

.isFilterDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 14px;
  right: 14px;
}

/* Right-side modal positioning */
.right-side-modal {
  // max-width: 300px;
  position: fixed;
  right: 0;
  top: 10;
  bottom: 0;
  margin: 0;
  transform: none;
  transition: transform 0.3s ease-in-out;
}

.right-side-modal .modal-dialog {
  position: fixed;
  right: 0;
  margin-top: 10;
  // width: auto;
  // max-height: 92%;
  display: flex;
  justify-content: flex-end;
}

// .right-side-modal .modal-content {
//   height: 95%;
//   /* Make the modal stretch full height */
// }

.activity-modal-body {
  max-height: 505px;
  width: max-content;
  overflow-y: auto;

  .activity-modal-body-data {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    .activity-icon {
      padding: 0.5rem;
      background: #ededed;
      margin-right: 1rem;
      border-radius: 1rem;
    }

    .createdAt {
      color: #63686f;
      font-size: smaller;
      font-weight: 500;
    }

    .updateHistory {
      font-size: 12px;

      .ticket-created {
        font-weight: 600;
      }

      .ticket-status-description {
        display: flex;

        div {
          width: max-content;
          margin-right: 4px;
        }

        .newValue {
          background: #ededed;
          border-radius: 4px;
          padding: 2px 4px;
          font-size: x-small;
          font-weight: 600;
        }
      }
    }

    .changedBy {
      font-size: x-small;
      color: #63686f;
      font-weight: bold;
    }
  }

}

.hover-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.excel-image {
  width: 70%;
  height: 100%;
  object-fit: contain;
  transition: opacity 0.3s ease;
  margin-left: 10px;
}

.hover-container:hover .excel-image {
  opacity: 0.5;
}

.hover-download-icon {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  display: none;
  width: 30px;
  height: 30px;
}

.hover-container:hover .hover-download-icon {
  display: block;
}

.download-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.clone-ticket {
  background: #00b598;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 8px;
  color: white;
  width: max-content;
  font-size: smaller;
}

.ticket-search-basedOnSubDesc {
  padding: 0 0 0.7rem 0;

  .search-filter {
    padding: 4px 8px;
    border-radius: 8px;
    font-size: smaller;
    cursor: pointer;
    margin-right: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;

    // &:hover{
    //   background: #ecf8f0;
    //   color: #00b598;
    // }
  }
}

.modal-backdrop {
  background-color: rgba(20, 18, 18, 0.103);
}

.editNotes {
  width: 0px;
}

.custom-dropdown-toggle {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  font-size: small;
  color: #666e7e;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;
}

.custom-dropdown-item:hover {
  background-color: #f5f5f5 !important;
  /* Optional: Light gray on hover */
}

.custom-dropdown-item svg {
  margin-left: 10px;
  color: #007bff;
  /* Optional: Set tick icon color */
}

.custom-dropdown-item {
  margin-bottom: 0.5rem;
  width: 10rem;
  cursor: pointer;
}

.removeMention {
  background: #ffdad6;
  color: #131212;
  font-size: 11px;
  padding: 5px;
  border-radius: 15px;
  margin-left: auto;
  width: 32%;
  text-align: center;
}

.disableRemove {
  opacity: 0.65;
  cursor: not-allowed;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  position: relative;
  left: 20%;
}

.pagination-btn {
  background: #fff;
  border: 1px solid #00b598;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s ease, border 0.3s ease;
  width: 35px;
  height: 35px;
}

.pagination-btn:not(:disabled):hover,
.pagination-btn:not(:disabled):focus,
.pagination-btn:not(:disabled):active {
  background: #00b598 !important;
  color: #fff !important;
}

.pagination-btn span {
  color: #00b598;
  font-size: 18px;
}

.pagination-btn:hover span,
.pagination-btn:focus span,
.pagination-btn:active span {
  color: #fff !important;
}

.pagination-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-btn:disabled span {
  color: #00b598 !important;
}