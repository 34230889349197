
// project release version - 3.0.0
$container-background:#F4F4F4;
$sidebar-background:#353535;

$card-background: #FFFFFF;
$card-primary-background: #06AF4333;
$card-secondary-background: #F05A2833;

$primary-text-color: #6E6E6E;
$secondary-text-color: #06AF43;
$tertiary-text-color: #F05A28;

$connect-btn-background: #F05A28;
$submit-btn-background: #06AF43;

$text-header-color: #5b5b5b;

$secondary-background-color-blur :#f05a2826;
$app-know-more-text: #3897F1;

//DashboardV4
$app-background-color: #F0F0F0;
$light-theme-background: #CDEFD9;
$dark-theme-background: #06AF43;
$light-text-color: #4B4B4B