// @import "../assets/scss/_variables.scss";
@import "../_variable.scss";

.biz-button-connect,
.biz-button-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem 1rem;
  border-radius: 1.5rem;
  color: white;
  gap: 4px;

  .biz-button-icon {
    display: flex; // Ensures the icon aligns properly
    align-items: center;
    justify-content: center;
  }
}

.biz-button-connect {
  background: $connect-btn-background;
}

.biz-button-submit {
  background: $submit-btn-background;
}
