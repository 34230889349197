// @import "../../_variable.scss";
@import "../../variable.scss";

.app-profile{
  border-radius: 50%;
  padding: 4px 8px;
  display: "flex";
  align-items: "center";
  cursor: pointer;

  &:hover{
    background: $light-theme-background;
  }
}

.empty-avatar{
  border: 1px solid #06AF43;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #06AF43;
  font-weight: 600;
}

.profile-info-popover{
  padding: 0.5rem;

  .prf-option{
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    border-radius: 8px;

    .opt-icon{
      margin-right: 0.5rem;
    }

    &:hover{
      background: #F0F0F0;
    }
  }
}

//v4 - header
.app-header-actions{
  display: flex;
  align-items: center;

  .biz-header{
    display: flex;
    align-items: center;

    .app-action{
      display: flex;
      align-items: center;
      margin-right: 1.5rem;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 8px;
      stroke: #1e1e1e;
      
      &:hover{
        color: $dark-theme-background;
        stroke: $dark-theme-background;
      }

      .action-icon{
        margin-right: 0.25rem;

        svg{
          scale: 1.2;
        }
      }

      .action-label{
        font-size: 14px;
        font-weight: 500;
      }

      // &:hover{
      //   background: $app-background-color;
      // }
    }
  }
}

//-*---------------*- HEADER -*---------------*-//
.helpContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .helpIcon {
    background: #71839b;
    color: #fff;
    padding: 4px 5px;
    border-radius: 4px;
  }

  // .customSelectOption{
  //     cursor: pointer;
  //     font-size: 8px;
  //     display: flex !important;
  //     width: 100;
  //     .label{
  //         font-size: 8px !important;
  //     }
  // }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {

  /* Styles for mobile portrait screens */
  #root>div>div.page>div.open {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
  }

  #navbar {
    .main-header-left {
      .responsive-logo {
        .header-logo {
          position: absolute;
          top: -8%;

          img {
            height: 4rem;
            width: 40%;
          }
        }
      }

      .app-sidebar__toggle {
        display: flex;
        justify-content: center;
        align-items: center;

        .open-toggle {
          color: #2a2e3f;

          svg {
            height: 1rem;
            width: 1rem;
          }
        }

        .close-toggle {
          display: none;
        }
      }
    }

    .main-header-right {
      .navresponsive-toggler {
        padding: 0 !important;
      }

      #navbarSupportedContent-4 {
        background-color: white;
        z-index: 10;

        .organizationSelect {
          width: 100%;
          justify-content: flex-end;
          margin: 0;

          ::before {
            content: attr(data-content);
            display: block;
          }

          .content-div {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            font-weight: 500;
            color: #3e3e3e;
          }
        }


        .headerRightNav {
          margin: 0;

          div {
            display: flex;
            align-items: center;
            // height: 70%;

            svg {
              padding: 0;
            }

            span {
              margin-top: 4px;
              font-size: small;
              font-weight: 500;
              color: #3e3e3e;
            }
          }
        }

        #user-profile {
          margin-left: 1rem !important;
          // height: 70% !important;

          #user-profile-btn {
            margin: 0;
          }
        }
      }
    }
  }
}

// @media only screen and (min-width: 481px) and (max-width: 747px) {
//   /* Styles for mobile landscape screens */
// }

@media only screen and (min-width: 481px) and (max-width: 1024px) {

  /* Styles for tablet portrait screens */
  #root>div>div.page>div.open {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
  }

  .main-header-left {
    .responsive-logo {
      .header-logo {
        position: absolute;
        top: -8%;

        img {
          height: 4rem;
          width: 20%;
        }
      }
    }

    .app-sidebar__toggle {
      display: flex;
      justify-content: center;
      align-items: center;

      .open-toggle {
        color: #2a2e3f;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }

      .close-toggle {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  /* Styles for tablet landscape screens */

  .main-header-left {
    .open-toggle {
      display: none;
    }

    .close-toggle {
      display: none !important;
    }
  }

  .main-header-right {
    position: fixed;
    right: 0;

    #desktop-props {
      display: block;
    }
  }
}

.css-1isrtdu-control,
.css-1mw81l1-control {
  width: 120px !important;
}

.css-1fdsijx-ValueContainer,
.css-1hb7zxy-IndicatorsContainer {
  padding: 0px !important;
}

.fontSize {
  font-size: 20px;
  color: #71839b;
  margin: 0 5px;
}

.organizationSelect {
  // border: 1px solid #5dadd8;
  border-radius: 5px;
  margin: 10px 0px;
  padding: 0px 5px;
  cursor: pointer;
}

.createChannel {
  position: relative;
  top: 20%;
  padding: 5px 5px;
  font-weight: 500;
}

.headerRightNav div {
  margin: 3px 3px;
}

.helpIcon {
  color: #fff;
  background: #71839b;
  padding: 3px 5px;
  border-radius: 5px;
  margin: 0 5px;
}

.headerRightNav {
  display: flex;
  margin: 18px 5px;
}

.main-profile-menu .btn.show {
  color: #fff !important;
  background-color: none !important;
  border-color: none !important;
}

.textSize {
  // color: var(--primary-gray, #324054);
  // color: #7b8191;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &:hover::after {
    color: red;
  }
}

.profileimg-header {
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 50%;
}

.rounded-circle {
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid lightgray;
  height: 2rem;
  width: 2rem;
}

.custom-dropdown-menu {
  left: auto !important;
  right: 0 !important;
  margin-right: 10%;
  position: relative;
}

.color {
  background: #71839b;
}

// .trialmargin {
//   margin-top: 1.6%;
// }

.profiletext {
  padding-left: 5px;
}

.profilemargin {
  margin-left: -8px;
  margin-bottom: 6px;
}

.custom-dropdown-toggle::after {
  display: none;
}

.custom-dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: black;
  box-shadow: none !important;
}

.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus {
  color: black !important;
}

.custom-dropdown-channel-menu {
  left: auto !important;
  right: 0 !important;
  overflow-x: scroll;
  max-height: 400px;
  padding-top: 0px;
  width: 350px;

  .search-sticky {
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: center;
  }
}

.dropdown-item-custom:focus {
  background-color: #ecf8f0 !important;
  color: #06AF43;
}

.dropdown-item-custom:hover {
  background-color: #ecf8f0 !important;
  color: #06AF43;
}

.custom-menu {
  padding-bottom: 3px;
}

.totalCount {
  font-size: 9px;
  background-color: #3c96fd;
  padding: 3px;
  color: white;
  border-radius: 25px;
  position: relative;
  right: 12px;
}


// .app-header-left {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #00b598;
//   color: white;
//   font-weight: 500;
//   padding: 0.25rem 0.3rem;
//   border-radius: 6px;
// }


.app-header-left {
  position: relative;
  /* Needed for the pseudo-element to position relative to this */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00b598;
  /* Your theme color */
  color: white;
  font-weight: 500;
  padding: 0.25rem 0.3rem;
  border-radius: 6px;
  overflow: hidden;
  /* Ensures the effect doesn't overflow outside the div */
}

.app-header-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: -30%;
  /* Start off-screen to the left */
  width: 10%;
  /* Smaller width for the glow effect */
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  /* White color with transparency for the glass-like effect */
  // border-radius: 6px;
  transform: skewX(-25deg);
  /* Create the angle for the glow */
  animation: slide 3s infinite linear;
  /* Smooth sliding animation */
}

@keyframes slide {
  0% {
    left: -30%;
  }

  100% {
    left: 100%;
  }
}

.helpDocs {
  position: relative;
  bottom: 3.5px;
  right: 6px;
}

.adjustHeader {
  position: relative;
  bottom: 2px;
}

.notificationScroll {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 15px;
  overflow-x: hidden;
}

.notificationItems {
  padding: 12px;
  width: 220px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12.5px;
  word-wrap: break-word;

  a {
    color: unset;
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.notiMsgs {
  padding-top: 5px;
  position: relative;
  right: 3px;
}

.assignedTo {
  background: #dfeff8;
  border-radius: 4px;
  padding: 3px;
  margin: 4px;
  font-weight: 550;
}

.assignedFrom {
  position: relative;
  top: 5px;
  background: #f3d0c1;
}

.reminderMsg {
  background: rgb(255, 248, 213);
  padding: 5px;
}

.previousAssigned {
  background: rgb(245 237 188);
}

.ticketClosed {
  background: rgb(198 206 243);
}

.ticketCreatedNoti {
  background: rgb(189 245 236);
}

.hovercopyIcon:hover {
  color: gray;
}