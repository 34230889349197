$pagination-color: #00b598;
$pagination-hover-color: #458d80;
$global-bg-color: #fbf8f8;
$global-border-color: #e2e2e2;
$disable: #86b5ac;

.table> :not(caption)>*>* {
  border-bottom-width: none !important;
}

.EXIST-container,
.EXISTCOMMUNITY-container {
  .name-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    left: 2rem;
    width: 20rem !important;
  }
}

.tableContainer {
  width: 100%;
  /* Adjust based on your requirements */
  overflow-x: hidden;
  background-color: white;

  #table-header {
    position: sticky;
    top: 0;
    z-index: 6;
    border: none;
  }

  .checkbox-cell,
  .selection-cell {
    width: 2rem;
    text-align: center;
    position: sticky;
    left: 0;
    z-index: 5;
    /* Ensure the sticky columns are above other columns */
    background-color: white;
    /* Optional: Ensure the sticky columns have a white background */
  }

  .serialNumber-cell {
    width: 3rem;
    text-align: center;
    position: sticky;
    left: 0;
    z-index: 5;
    /* Ensure the sticky columns are above other columns */
    background-color: white;
  }

  .name-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    left: 2rem;
    width: 10rem;
  }


  .teamName-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    left: 2rem;
    width: 20rem;
  }


  .Description-cell,
  .id-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    // left: 2rem;
    width: 13.5rem;
  }


  .imageUrl-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    // left: 2rem;
    width: 4.5rem;
  }

  .contact-cell {
    text-align: start;
    position: sticky;
    z-index: 5;
    left: 2rem;
    width: 12.5rem;
  }

  .actions-cell {
    width: 11rem;
    text-align: center;
    position: sticky;
    right: 0;
    z-index: 5;
    /* Ensure the sticky columns are above other columns */
    background-color: white;
    /* Optional: Ensure the sticky columns have a white background */
  }

  .mobileNumber-cell,
  .email-cell,
  .contactType-cell,
  .tags-cell,
  .source-cell,
  .updatedAt-cell,
  .updatedAtFlow-cell,
  .flowInstances-cell,
  .flowName-cell,
  .createdBy-cell,
  .sessionTTL-cell,
  .createdByBot-cell {
    text-align: start;
    // width: 25rem;
    z-index: 1;
  }

  .totalQuantity-cell {
    width: 4rem;
  }

  .orderItems-cell {
    width: 5rem;
  }

  .totalAmount-cell {
    width: 6rem;
  }

  .orderChannel-cell {
    width: 2rem;
  }

  .sessionTTL-cell,
  .createdByBot-cell,
  .flowInstances-cell,
  .flowStatus-cell,
  .modifiedOn-cell {
    width: 10rem;
  }



  .updatedAtFlow-cell,
  .flowStatus-cell {
    width: 8rem;
  }

  .flowName-cell,
  .uid-cell {
    width: 8rem;
  }

  .createdBy-cell {
    width: 12rem;
  }

  .BOT {
    max-height: 515px !important;
  }

  .apiKey-cell {
    width: 20rem;
    position: relative;
    left: 13rem;

  }

  .apiKeys-head {
    width: 20rem;
  }


  .createdAt-cell {
    text-align: start;
    position: sticky;
    width: 9rem;
  }

  .mobileNumber-cell,
  .email-cell {
    width: 12rem;
  }

  .contactType-cell,
  .tags-cell,
  .source-cell {
    width: 8.2rem;
  }

  .description-cell {
    width: 16.2rem;
  }

  .updatedAt-cell {
    width: 10rem;
  }


  // .uid-cell{
  //   width: 20rem;
  // }


  /* Make the first two columns sticky */
  // .checkbox-cell {
  // }

  // .actions-cell {
  // }

  .scrollTable {
    border-collapse: collapse;
    width: 100%;
  }

  .scrollTable thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* This ensures columns have the same width */
  }

  .scrollTable tbody {
    display: block;
    max-height: 360px;
    /* Adjust based on your requirement for scroll */
    overflow-y: auto;
    width: 100%;
  }

  .TAGS {
    max-height: 465px !important;
  }

  
  .GROUPCREATE_2 {
    .name-cell {
      width: 22rem !important;
    }

    .createdAt-cell {
      width: 15rem !important;
    }

    .memberCount-cell {
      width: 15rem !important;
    }

    .actions-cell {
      width: 22rem !important;
    }

    max-height: 430px !important;
  }
.QUICKREPLY{
  .name-cell {
    width: 14rem !important;
  }
  .content-cell {
    text-align: left;
  }
  max-height: 430px !important;
}

  .MEMBERS {
    max-height: 430px !important;
  }

  .BOT_FLOW {
    max-height: 500px !important;
  }

  .PAYMENT_HISTORY {
    max-height: 415px !important;
  }

  .CATALOGUE {
    max-height: 470px !important;
  }

  .ORDER {
    max-height: 730px !important;
    overflow: hidden;
  }

  .PRODUCTLOG {
    max-height: 440px !important;
  }

  .TEMPLATE_ANALYTICS{
    max-height: 410px !important;
  }

  .COMPANIES {
    max-height: 440px !important;
  }

  .COMMUNITY {
    max-height: 365px !important;
  }

  .CONTACTS {
    max-height: 410px !important;
  }

  .EXIST {
    max-height: 435px !important;
  }

  .APIKEY {
    max-height: 490px !important;
  }

  .FAILED_MESSAGES {
    max-height: 460px !important;
  }

  .APPOINTMENTS {

    // max-height: 290px !important;
    .contact-cell {
      text-align: start;
      padding-left: 2rem;
    }

    .mobileNumber-cell{
      width: 9rem !important;
      }
  }

  .APPPOINTMENTS-container {
    background-color: white !important;
  }

  .HOLIDAYS {
    max-height: 470px !important;
  }

  .scrollTable th {
    padding: 8px;
  }

  .scrollTable th {
    background-color: #eff4fa;
    /* Adjust based on your requirements */
  }

  .scrollTable tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .table tbody tr {
    border-top: none;
    // background-color: rgba(255, 255, 255, 0.5);
    // border-bottom: .5px solid  #E2E2E2;
  }


  // .table td {
  //   border-top: none;
  // }

  input[type="checkbox"] {
    accent-color: #009980;
  }



}

.TICKETSLIST-container {
  .id-cell {
    width: 7rem !important;
  }

  .contact-cell {
    width: 15rem;
  }

}

.CAMPAIGNOVERVIEW-container {
  .creatorId-cell {
    width: 10rem;
    padding-left: 0px;
  }

  .totalCount-cell,
  .sentCount-cell,
  .deliveredCount-cell,
  .readCount-cell,
  .repliedCount-cell,
  .clickedCount-cell,
  .failedCount-cell {
    width: 4.1rem;
  }

  .edit-cell {
    width: 8rem;
  }

  .actions-cell {
    width: 7rem;
  }

  .createdAt-cell {
    width: 7rem;
  }
}

.SEQUENCELOGS {
  .contactName-cell {
    text-align: left;
  }
}


//----------------- no records  ----------------------------//

.empty_state {
  .recordsNotfound {
    text-align: center;
    position: relative;
    top: 10em;
    font-size: 17px;
  }
}

.paginationContainer {
  //----------------- table pagination ----------------------------//

  background-color: white;
  height: 50px !important;
  padding: 0;

  .react-paginate {
    display: flex;
    list-style-type: none;
    padding: 0;
  }

  /* Styling for each page number link */
  .react-paginate li a {
    padding: 8px 12px;
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid $pagination-color;
    /* Blue border color */
    color: $pagination-color;
    /* Blue text color */
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
  }

  /* Styling for the active page number */
  .react-paginate li.selected a {
    background-color: $pagination-color;
    /* Blue background for the active page */
    color: white;
    /* White text for contrast */
  }

  .paginationContainer {
    //----------------- table pagination ----------------------------//

    .react-paginate {
      display: flex;
      list-style-type: none;
      padding: 0;
      // justify-content: center; /* Center pagination */
    }

    /* Styling for each page number link */
    .react-paginate li a {
      padding: 8px 12px;
      margin: 0 4px;
      border-radius: 4px;
      border: 1px solid $pagination-color;
      /* Blue border color */
      color: $pagination-color;
      /* Blue text color */
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      display: flex;
      align-items: center;
    }

    /* Styling for the active page number */
    .react-paginate li.selected a {
      background-color: $pagination-color;
      /* Blue background for the active page */
      color: white;
      /* White text for contrast */
    }
  }

  .scrollTable th {
    padding: 8px;
  }

  .scrollTable th {
    background-color: #eff4fa;
    /* Adjust based on your requirements */
  }

  .scrollTable tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .table tbody tr {
    border-top: none;
    // background-color: rgba(255, 255, 255, 0.5);
    // border-bottom: .5px solid  #E2E2E2;
  }

  .table td {
    border-top: none;
  }

  input[type="checkbox"] {
    accent-color: #009980;
  }

  //----------------- no records  ----------------------------//

  .empty_state {
    .recordsNotfound {
      text-align: center;
      position: relative;
      top: 10em;
      font-size: 17px;
    }
  }

  /* Hover effect for each page number link */
  .react-paginate li a:hover {
    background-color: $pagination-hover-color;
    color: white;
  }

  .react-paginate li.disabled a {
    color: #cccccc;
    /* Light grey color */
    cursor: not-allowed;
    /* Show a disabled cursor */
    border-color: #cccccc;
    /* Light grey border */
  }

  .react-paginate li.break a {
    border: none;
    /* No border for the ellipsis */
    cursor: default;

    /* Default cursor for the ellipsis */
    &:hover {
      border: none;
      /* No border for the ellipsis */
      cursor: default;
      color: $pagination-color;
      /* Blue text color */

      background: none;
    }
  }

  .paginate-dropdown {
    float: right;
    width: 7em;
  }
}

.transparent {
  background-color: transparent !important;
}

.catalogueProducts-cell {
  text-align: start;
  position: sticky;
  z-index: 5;
  width: 15rem !important;
}

//-------------- ADD CREDITS-------------//
.CREDIT-container {
  .amount-cell {
    width: 7rem;
    text-align: left;
    margin-left: 3px;
  }
}

// -------------- MEMBERS----------------//
.MEMBERS-container {
  .name-cell {
    width: 12rem;
  }
}

// -------------- GEOFENCE REPORT----------------//
.GEOFENCEREPORT-container {
  .scrollTable tbody {
    display: block;
    max-height: 500px;
    /* Adjust based on your requirement for scroll */
    overflow-y: auto;
    width: 100%;
  }

  .mobileNumber-cell,
  .name-cell {
    width: 15rem;
  }
}

// -------------- TEMPLATES ----------------//
.TEMPLATES-container {

  .name-cell {
    width: 12rem !important;
  }

  .bodyMessage-cell {
    width: 10rem !important;
  }

  .createdAt-cell {
    text-align: center;
    width: 7rem !important;
  }

  .scrollTable tbody {
    display: block;
    max-height: 425px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
  }

  .templateActions-cell {
    text-align: -webkit-left;
  }
}

.channel-cell {
  width: 6rem;
}


.select-cell {
  width: 4rem;
  text-align: left;
}


// Roles

.ROLES-container {
  .type-cell {
    width: 15rem;
  }

  .createdBy-cell {
    align-items: center;
    width: 45rem;
    text-align: center;
  }

  .scrollTable tbody {
    max-height: 500px;
  }

}



// -------------- BOTLIBRARY ----------------//
.BOTLIBRARY-container {

  .flowSelection-cell {
    width: 10rem;
  }

  .flowName-cell {
    width: 15rem;
  }

  .flowInstances-cell {
    width: 15rem;
  }

}

.FAILED_MESSAGES-container {

  .scrollTable tbody {
    display: block;
    max-height: 425px;
    overflow-y: auto;
    width: 100%;
  }

  .templateName-cell {
    position: sticky;
    z-index: 5;
    left: 2rem;
    text-align: left;
    width: 16rem;
  }

  .contactName-cell,
  .assignTo-cell {
    text-align: left;
    width: 16rem;
  }

  .mobileNumber-cell {
    text-align: left;
    width: 17rem;
  }

  .createdAt-cell {
    position: sticky;
    text-align: left;
    width: 16rem;
    right: 0;
  }

}

.BOT_LOGS {


  .status-cell {
    width: 12rem;
  }

  .flowName-cell {
    width: 10rem;
  }

  .uid-cell {
    width: 17rem;
  }
}

.BOT_LOGS-container {

  .scrollTable tbody {
    display: block;
    max-height: 420px;
    overflow-y: auto;
    width: 100%;
  }

}

.INTEGRATIONS-container {
  .customerName-cell {
    width: 10rem;
  }

  .mobileNumber-cell {
    text-align: center;
    width: 20em;
  }

  .createdAt-cell {
    width: 15em;
  }

  .workFlow-cell {
    width: 15em;
  }

  .customerName-cell {
    width: 20em;
  }
}

// -------------- ORDER ----------------//

.ORDER-container {
  .orderProduct-cell {
    text-align: left;
  }

  .mobileNumber-cell {
    width: 12rem !important;
  }

  .createdAt-cell {
    width: 12rem;
    text-align: center;
  }


}

.APPOINTMENTREPORT, .TICKETREPORT {
  max-height: 520px !important;
}


.CONVERSATIONREPORT {
  max-height: 520px !important;
  .createdAt-cell {
    width: 26rem;
  }
  .name-cell {
    width: 8rem;
  }
}

.WHATSAPPFLOW {
  max-height: 520px !important;
}

.Community_Container,.contact-container{
  height: 100vh !important;
}