.auto {
color: #000;
font-family: Public Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
}

.labelstyle {
    font-size: 16px;
    font-weight: 500;
}

.box {
    width:20%;
    background-color:#00b598;
    border-bottom: 3px solid #00b598;
    border-radius: 4px;
    padding:6px;
    font-size: 13px;
}

.box label {
    // color: #00b598;
    color: white;
}

.notselect {
    width:20%;
    background-color:#fff;
    border: 1px solid rgb(236, 234, 234);
    border-radius: 4px;
    padding: 6px;
    font-size: 13px;
}

.cancelBtn {
    background: #FBF8F8;
    border: 1px solid #d6d2d2;
    margin-top: 10px;
    color: black;
    box-shadow: none;
    font-size: 14px;
}

.tab-nav-item {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: #acacac;
  }
  
  .tab-nav-item.active {
    color: rgb(73, 73, 73); 
  }
  
  .tab-nav-item.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(136, 192, 245); 
  }
  .textstyle {
    font-size: 14px;
    font-weight: 400;
  }
  .smalllabel {
    font-size: 14px;
    font-weight: 600;
  }

  .switchBtn {
    position: absolute;
    margin-left: 50%; 
  }
  .iconBg {
    height: 40px;
    margin-left: 1%;
  }
  .timer {
    background-color: #fcfafa;
    width: 40%;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 3px;
  }

  .fieldwidth{
    width: 29.6%;
    margin-left: 1%;
  }

  .margin-bottom {
    margin-bottom: 10% ;
  }

.delete {
  margin-left: 7%;
  background-color: rgb(252, 252, 252);
  color: #d4d0d0;
  border: none;
  font-size: 16px;
}  

.tabshow {
  width: auto;
  display: inline-block;
  margin-right: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: #eaebeb;
  border: 1px solid #a8aaaa;
  border-radius: 4px;
  padding: 7px;
}

.position {
  position: absolute;
  left: 65%;
  // top: 83%;
  bottom: 10%;
}

.infotext {
  font-size: 12px;
  font-weight: 600;
  color: #acacac;
}

.emailcolor {
  background-color: #ffffff !important;
}

.labelcolor {
  color: #007ec3;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {

  #chatInbox {
    width: 50%;
  }

  #chatConfig {
    width: 50%;
  }

  #rowTime {
    width: 260px;
    background-color: #ffffff;
  }

  #daySelect {
    width: 300px;
  }
 
  #timeSelect {
    width: 120px;
  }

  #daysSelect {
    width: 120px;
    margin-left: -10px;
  }

  #deleteIcon {
    background-color: white;
  }

  .applyButton {
    width: 80px;
    margin-top: 150px;
  }

  #switchButton {
    margin-left: 210px;
    max-width:40px;
  }

  #delayed {
    font-size: 14px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet portrait screens */
  #daysSelect {
    width: 120px;
    margin-left: -30px;
  }

  .applyButton {
    width: 80px;
    margin-top: 150px;
  }
}

.nav-tabs{
  display: flex;
  // justify-content: center;
  font-size: 14px;
  font-weight: 500;
  gap: 0.75rem;
  border: none;
  margin-bottom: 1.5rem;
  
  .toggle-tabs-selected{
    color:  #049fdb;
    border: 1px solid #c8ced3;
    border-bottom: white;
    padding: 8px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 14rem;
    text-align: center;
    cursor: pointer;
  }
  .toggle-tabs-{
    color:  black;
    border-bottom: white;
    padding: 8px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 14rem;
    text-align: center;
    cursor: pointer;
    border: 1px solid white;

    &:hover{
      border: 1px solid #e5ebf0;
    }
  }
}
