body {
  position: relative;
  /* or any other positioning value you want */
  /* Other CSS styles for the body */
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles for mobile portrait screens */

  #template-page {
    margin-left: 0 !important;

    .pageView,
    .hideBorder {
      width: 100%;
    }

    .pageView {
      display: block;

      #template-operation {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.5rem;
        margin-right: 0;
      }

      .sel-category,
      .sel-status {
        margin-bottom: 0.5rem;
        margin-right: 0;
      }

      .crt-template-btn {
        display: flex;
        justify-content: center;
        margin-right: 0;

        .createTemplate {
          width: 100%;
        }
      }
    }
  }

  .templateContainer {
    .positionVideo {
      iframe {
        height: 210px;
        width: 230px;
      }
    }
  }

  div.breadcrumb-header>div.searchWidth.ml-auto.input-group {
    width: 100%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  /* Styles for mobile landscape screens */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet portrait screens */

  div.breadcrumb-header>div.searchWidth.ml-auto.input-group {
    width: 40%;
    margin-right: 0.68rem;
  }

  #template-page {
    margin-left: 0 !important;

    .breadcrumb-header {
      margin-bottom: 0.5rem;
    }

    .pageView,
    .hideBorder {
      width: 100%;
    }

    .pageView {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media only screen and (min-width: 1025px) {
  /* Styles for tablet landscape screens & Desktop*/
}

.required:after {
  margin-left: 0.5px;
  margin-top: 5px;
  content: " *";
  color: red;
  font-size: 12px;
}

// .headerOptions {
//   display: flex;
//   flex: 4;
// }

.header-form {
  margin-bottom: 0 !important;
}

.headerRadioBtns {
  position: relative;
  left: 10%;
  bottom: 1.5px;
}

ul {
  list-style: none;
  padding: 5px 0px 5px 0px;
  margin: 0px;

  li {
    display: inline;
    // padding-right: 15px;
  }
}

.headerOptnLabel {
  padding-left: 5px;
  padding-right: 15px;
  position: relative;
  top: -2px;
}

.headerbox {
  background: #fbfbfb;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 10px;
}

.addDelButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
}

.mpmsectionbox {
  background: #F7F8FB;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 15px;
  padding-bottom: 0px;
  width: 45rem;
  // margin-bottom: 10px;
  margin-top: 10px;
}

.catalogbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #EBF7FD;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}

.catalogbox>div {
  display: flex;
  flex-direction: column;
}

.catalogbox .product-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.catalogbox .last-sync {
  align-self: flex-end;
  position: relative;
  top: -50px;
}


.seperationLine {
  border-bottom: 1px solid #e2e2e2;
}

.bodyOptions {
  display: flex;
  flex: 2;
}

// .whatsappImage {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
// }

.layout {
  background-color: #fff;
  position: absolute;
  width: 49%;
  padding: 10px;
  overflow: hidden;
  word-wrap: break-word;
  top: 17%;
  border-radius: 4px;
}

.seperateSample {
  position: relative;
  left: 3%;
}

.actionBtns {
  display: flex;
  flex: 3;
}

.addRemoveIcon {
  position: relative;
  top: 4px;
  font-size: 20px;
}

.addIcon {
  color: #007ec3;
}

.removeIcon {
  color: #df3b3b;
  position: relative;
  left: 5px;
}

.dotted-box {
  border: 1px dotted #bbbbbb;
  padding: 20px;
  margin-top: 10px;
  position: relative;
}

.dragHeader {
  font-size: 12px;
  color: #9b959f;
}

.dragContent {
  font-size: 10px;
  color: #9b959f;
  padding-top: 10px;
}

.uploadFiles1 {
  text-align: right;
  display: block;
  margin-top: -22px;
}

.uploadFiles1 span {
  background: #007ec3;
  border-radius: 2px;
  padding: 7px;
  cursor: pointer;
  color: #fff;
}

.replyActionBtns {
  top: 75.5%;
  color: #007ec3;
  font-weight: 500;
  width: 53%;
}

.whatnumber {
  color: #f5f7f8;
  font-size: 15px;
}

.logo {
  // position: absolute;
  // top: 9%;
  // left: 34%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: auto;
}

.plane {
  display: flex;
  margin-top: -14%;
  margin-left: 80%;
}

.whatmessage {
  margin-top: 153%;
  width: 96%;
  height: 7%;
  border-radius: 15px;
  color: #121213;
  background-color: #ffffff;
}

.whatmessagesend {
  position: absolute;
  bottom: 35%;
  margin-top: 50%;
  margin-bottom: 2%;
  top: auto;
  width: 30%;
  border-radius: 15px;
  color: #121213;
  background-color: #dcf8c6;
}

.tempInputs {
  border: none;
  background: #fbfbfb;

  &:disabled {
    border: none;
    background: #fbfbfb;
  }
}

.btnwidth {
  width: 85%;
}

.formcontrol {
  &:disabled {
    background: #fbfbfb;
  }
}

.tempInputs:focus {
  border: none;
  background: #fbfbfb;
}

.tempInputsBorder {
  border: 1px solid #e2e2e2;
  background: #fbfbfb;
}

.tempInputsBorder:focus {
  border: 1px solid #e2e2e2;
  background: #fbfbfb;
}

Label .label {
  color: black;
}

.btn-group {
  margin-bottom: 10px;
  color: black;
  cursor: pointer;
}

.btn-group button:nth-child(1) {
  background: linear-gradient(0deg,
      rgba(101, 84, 202, 0.3),
      rgba(101, 84, 202, 0.3)),
    #ffffff;
  border: 1px solid #6554ca;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  box-shadow: none;
  font-size: 12px;
}

.btn-group button:nth-child(2) {
  background: linear-gradient(0deg,
      rgba(104, 212, 255, 0.3),
      rgba(104, 212, 255, 0.3)),
    #ffffff;
  border: 1px solid #68d4ff;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  box-shadow: none;
  font-size: 12px;
}

.btn-group button:nth-child(3) {
  background: linear-gradient(0deg,
      rgba(200, 202, 84, 0.3),
      rgba(200, 202, 84, 0.3)),
    #ffffff;
  border: 1px solid #c8ca54;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  box-shadow: none;
  font-size: 12px;
}

.btn-group button:nth-child(4) {
  background: linear-gradient(0deg,
      rgba(191, 196, 198, 0.3),
      rgba(191, 196, 198, 0.3)),
    #ffffff;
  border: 1px solid #bfc4c6;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  box-shadow: none;
  font-size: 12px;
}

.var-align {
  position: relative;
  right: 100px;
}

.sampleVarColor {
  color: #ff7342;
  background-color: #efeae9;
  padding: 3px;
  border-radius: 25px;
}

.sampleFailedVarColor {
  color: #ff6565;
  font-weight: 600;
  background-color: #efeae9;
  padding: 3px;
  border-radius: 25px;
}

.tab-pane-content {
  margin-top: 10px;
  font-size: 16px;
}

.tabActive {
  border-bottom: 1px solid #007ec3 !important;
  font-weight: 500 !important;
}

.templateTabbar {
  font-size: 12px !important;
  margin-bottom: 0 !important;
}

.templateTabs {
  cursor: pointer;
}

.templateLibraryDisable{
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.5;
}

.searchWidth {
  width: 28%;
}

.pageView {
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  margin-top: 5px;

  #change-view-btn {
    display: flex;
    align-items: center;

    span {
      width: 2rem;
    }
  }
}

.eyeicon {
  cursor: pointer;
}

.pageView div {
  margin-right: 10px;
}

.pageViewBorder {
  border: 1px solid #e2e2e2;
  padding: 6px 0px 6px 0px;
  border-radius: 4px;
}

.listView {
  background: #fbfbfb;
  padding: 6px;
  width: 12%;
  border: 1px solid #e2e2e2;

  &:hover {
    background: #e0e1e2;
    cursor: pointer;
  }

  &:focus {
    background: #e0e1e2;
    cursor: pointer;
  }
}

.gridView {
  border-right: 1px solid #e2e2e2;
}

.active {
  // background: #2a2e3f;
  background: inherit;
  border: none;
}

.active1 {
  background: #eff1f1;
}

.createTemplate {
  background: #00b598;
  border: #00b598;
  color: #fff;
  padding: 9px;
  font-size: 14px;

  &:hover {
    background: #458d80;
    border: #458d80;
    color: #fff;
    padding: 9px;
  }

  &:focus {
    background: #00b598 !important;
    border: #00b598 !important;
    color: #fff;
    padding: 9px;
  }

  &:active {
    background: #00b598 !important;
    border: #00b598 !important;
    color: #fff;
    padding: 9px;
  }
}

hr {
  height: 1px;
  margin-top: 7px;
  border-top: 1px solid #666e7d;
}

.selectWidth {
  width: 100%;
}

.cardHeader {
  // background: #EFF4FA;
  // background:  #eeffe7; 1
  background: #f1fdf9;
  // background: #e3fbf3;3
  max-height: auto;
}

.strikeThrough {
  text-decoration: line-through;
}

.dynamic-variable {
  font-size: 15px;
}

.replyBtnsColor {
  color: #007ec3;
  font-weight: 600;
}

.failedReplyBtnsColor {
  color: #ff6565;
  font-weight: 600;
}

.headerImg {
  height: 80%;
  width: 100%;
}

.ellipsisIcon {
  display: inline;
}

.templateSelect:disabled {
  background-color: red;
}

.templateActions {
  cursor: pointer;
  padding: 5px;
  position: relative;
  bottom: 7px;

  &:hover {
    cursor: pointer;
    background: #fff;
    padding: 5px;
  }

  &:focus {
    cursor: pointer;
    background: #fff;
    padding: 5px;
  }
}

.dot {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  position: relative;
  left: 6px;
  bottom: 1px;
}

.rejectedStatus {
  color: #f00;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.approvedStatus {
  color: #07c717;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.draftStatus {
  color: #c501cc;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.pendingStatus {
  color: #d1920a;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.appealStatus {
  color: #20bbc0;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.pendingdelStatus {
  color: #cdeb23;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.deletedStatus {
  color: #2217bd;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.disableStatus {
  color: #ced4d8;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.pauseStatus {
  color: #eb8807;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.limitStatus {
  color: #187caa;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.submitStatus {
  color: #899944;
  cursor: pointer;
  font-size: smaller;
  font-weight: 600;
}

.language {
  border: 1px solid #e2e2e2;
  background-color: #fff;
  color: #aaa8a8;
  padding: 5px;
}

.language1 {
  padding: 0px 5px 5px 5px;
  background-color: white;
  font-size: 13px;
}

.iconFontSize {
  font-size: 13.5px;
}

.textToolbar {
  color: #7b7b7b;
  background: #ecedee;
}

.adjustHeight {
  overflow-y: auto;
  max-height: 40vh;
  margin: 1px;
  background: #ffffff;
}

.positionImage {
  position: relative;
  width: 200px;
  height: 150px;
}

.deleteImgIcon {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.positionVideo {
  position: relative;
  width: 300px;
  height: 200px;
}

.deleteVidIcon {
  position: absolute;
  top: 10.5%;
  right: 2.5%;
  background-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delIcon {
  color: #007ec3;
  font-size: 13.5px;
}

.delIcon:hover {
  color: rgb(24, 24, 24);
}

.moveRight {
  right: 19.5%;
}

.fas {
  color: #007ec3;
}

.layout1 {
  background-color: rgb(253, 252, 250);
  // position: absolute;
  margin-top: 12%;
  width: 85%;
  padding: 10px;
  overflow: hidden;
  word-wrap: break-word;
  // top: 25.5%;
  // left: 12.5%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 320px;
  overflow-y: auto;
  border-radius: 10px;
}

.layoutcarousel {
  background-color: rgb(253, 252, 250);
  // position: absolute;
  margin-top: 8%;
  width: 85%;
  padding: 10px;
  overflow: hidden;
  word-wrap: break-word;
  // top: 25.5%;
  // left: 12.5%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 180px;
  overflow-y: auto;
  border-radius: 10px;
}

.carouselTemps {
  background-color: rgb(253, 252, 250);
  margin-top: 5%;
  width: 85%;
  height: 40% !important;
  max-height: 40% !important;
  padding: 10px;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  word-wrap: break-word;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 180px;
  border-radius: 10px;
  display: flex;
  /* Ensure that children are aligned horizontally */
}

.carouselTempsCards {
  background-color: rgb(253, 252, 250);
  margin-top: 5%;
  width: 85%;
  height: 140%;
  // max-height: 115% !important;
  padding: 10px;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  word-wrap: break-word;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 180px;
  border-radius: 10px;
  display: flex;
  /* Ensure that children are aligned horizontally */
}

.templateRow {
  // background-color: rgb(253, 252, 250);
  padding: 10px;
  overflow: hidden;
  height: 110%;
  word-wrap: break-word;
  // box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 180px;
  border-radius: 10px;
  width: 200px;
  /* Define a fixed width for each template to allow scrolling */
  margin-right: 10px;
  /* Optional: add some spacing between templates */
  flex: 0 0 auto;
  /* Prevent the template from shrinking */
}

.replyActionBtns1 {
  background-color: rgb(253, 252, 250);
  // width: 85%;
  // top: 76.5%;
  color: #007ec3;
  word-wrap: break-word;
  font-weight: 500;
  // left: 12.5%;
  padding: 1% 3%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  border-radius: 10px;
}

.margin-top {
  margin-bottom: -11%;
}

.quickbtns {
  background-color: rgb(253, 252, 250);
  // position: absolute;
  // top: 40.5%;
  color: #007ec3;
  word-wrap: break-word;
  font-weight: 500;
  // left: 12.5%;
  // width: 50%;
  padding-left: 1%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  border-radius: 6px;
}

.quickbtns2 {
  background-color: rgb(253, 252, 250);
  // position: absolute;
  // top: 76.5%;
  color: #007ec3;
  word-wrap: break-word;
  font-weight: 500;
  left: 42%;
  padding-left: 1%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  border-radius: 6px;
}

// .fixed-component {
//     position: fixed;
//     top: 205px; /* Adjust this value to position the fixed component vertically */
//     left: 1000px; /* Adjust this value to position the fixed component horizontally */
//   }

.previewImg {
  // position: relative;
  width: 300px;
  height: 550px;
  padding-left: 20px;
  padding-top: 18px;
  // bottom: 60px;
  // left: 60px;
  // object-fit: cover;
  background-image: url("../../assets/img/previewPhone.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.urlBtns {
  display: flex;
  flex: 4;
}

.tempPreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fa-smile {
  color: #666e7d;
}

.eyeicon {
  font-size: 12px;
}

.emoji-popover {
  max-width: 75% !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.emoji-popover .popover-body {
  padding: 0;
  background-color: transparent !important;
}

.pdfSize {
  width: 320px;
  height: 200px;
}

.pdfSize::-webkit-scrollbar {
  // width: 8px;
  // /* for vertical scrollbars */
  // height: 8px;
  // /* for horizontal scrollbars */
  display: none;
}

.pdfSize::-webkit-scrollbar-thumb {
  background: #888;
}

.pdfSize::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pdfSizeInPreview {
  width: 217px;
  height: 125px;
  overflow: auto;
}

.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.templateContainer {
  max-width: 100%;
  overflow-x: hidden;
}

.fileName {
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
}

.textstyle {
  font-size: 12px;
}

.textstyle1 {
  font-size: 80%;
  color: #9a9b9c;
}

.txtbtn {
  background-color: #fcfbfb;
  display: inline-block;
  border: 1px solid #d4e6f0;
  border-radius: 8px;
  padding: 3px;
  // box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
}

.link-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 54%;
  background-color: rgba(15, 53, 83, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 29%;
  color: rgb(17, 16, 16);
}

.card-body:hover .link-overlay {
  display: flex;
}

.limitMsg1 {
  padding: 5px;
  background-color: #fff5dc;
  color: #a88300;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  // margin-bottom: 10px;

  svg {
    cursor: pointer;
  }
}

.addbtn {
  width: 14%;
  height: 5%;
  background-color: #44bcda;
  margin-top: 5px;
  font-size: smaller;
}

.addbtn:hover {
  background-color: #44bcda;
}

.addbtn:focus {
  box-shadow: none;
  background-color: #44bcda;
}

.footer {
  padding-left: 3px;
}

.previewImg.fixed {
  position: fixed;
  top: 8%;
  // height: 630px;
  // object-fit: cover;
}

.channelname.fixedchannelname {
  // position: fixed;
  text-align: left;
  right: 15%;
}

.layout1.fixedlayout {
  // position: fixed;
  // padding-right: 5%;
  // top:3%;
  text-align: left;
  margin-top: 12%;
}

.layoutcarousel.fixedlayout {
  text-align: left;
  margin-top: 10%;
}

.carouselTemps.fixedlayout {
  text-align: left;
  margin-top: 7%;
}

.previewBtn.fixedlayout {
  margin-top: 18%;
}

.width {
  width: 48%;
}

.cancel {
  color: #fbf8f8;
}

.cancelBtn {
  background: #fbf8f8;
  border: 1px solid #d6d2d2;
  margin-top: 10px;
  color: black;
  box-shadow: none;
  font-size: 14px;
}

.cancelBtn:hover {
  background: #fbf8f8;
  border: 1px solid #f7f5f5;
  margin-top: 10px;
  color: black;
  box-shadow: none;
  font-size: 14px;
}

.cancelBtn:focus {
  background: #fbf8f8;
  border: 1px solid #f7f5f5;
  margin-top: 10px;
  color: black;
  box-shadow: none;
  font-size: 14px;
}

.singleTempBg {
  background: #f4f5f7 !important;
  border-radius: 4px;
  // width: 60%;
  width: 75%;
  position: relative;
  left: 15%;
  bottom: 7%;
  max-height: 80vh;
  margin-top: 10px;
  overflow-y: auto;
  height: 80vh;

  &.full-width {
    width: 100%;
  }
}

.singleTempBtnWidth {
  width: 48%;
}

.convButton {
  width: 100%;
}

.mapNames {
  display: flex;
  flex-wrap: wrap;
  // width: 70%;
  // overflow: scroll;
  // max-height: 18em;
}

.mapColBtns {
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: -80%;
}

.redirectIcon {
  font-size: 16px;
  position: relative;
  left: 7%;
  top: 4%;
}

.singleTempBtns {
  display: block;
  padding: 3px;
  width: 100%;
  //background: rgb(254 235 200 / 80%);;
  // border: 1px solid #0E71C3;
  border-radius: 4px;
}

.componentMsgBorder {
  border: 1px solid #0e71c3;
}

//--------------TEMPLATE MODeL BUTTON  -----------------//
.model-switch-button-container {
  display: flex;
  justify-content: center;
  background-color: #fbf8f8;
  color: black;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  margin-bottom: 0.6em;
}

.noTempFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62vh;
  font-size: 14px;
}

.fontsize {
  font-size: 14px;
  font-weight: 600;
}

.optionBtn {
  // width: 8%;
  height: 2%;
  font-size: 10px;
  padding: 1px;
  text-align: center;
  margin-left: 2%;
  background-color: #e4f8f8;
  color: #121213;
}

.ftBtn {
  margin-left: 18px;
}

.optionBtn:focus {
  // width: 8%;
  height: 2%;
  font-size: 10px;
  padding: 1px;
  text-align: center;
  margin-left: 2%;
  background-color: hsl(180, 59%, 93%) !important;
  color: #121213 !important;
  box-shadow: none;
  border-color: #00b8d4;
}

// .optionBtn:hover {
//   width: 8%;
//   height: 2%;
//   font-size: 10px;
//   padding: 1px;
//   text-align: center;
//   margin-left: 2%;
//   background-color: #e4f8f8;
//   color: #121213;
// }

.templateBody {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fbfbfb inset !important;
  }
}

.channelname {
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding-left: 5%;
}

.advanceOpt-Container {
  .advanceOpts {
    padding-top: 0.6em;
    font-weight: 500;
    font-size: 0.875rem;
    color: #4a4a69;
  }
}

.temp-media-upload {
  background-color: #fbf8f8;
  border: 1px solid #e2e2e2;
  border-radius: 0px 4px 4px 4px;
  padding: 1em;
}

.advanceOpt-Container {
  .advanceOpts {
    padding-top: 0.6em;
    font-weight: 500;
    font-size: 0.875rem;
    color: #4a4a69;
  }
}

.temp-media-upload {
  background-color: #fbf8f8;
  border: 1px solid #e2e2e2;
  border-radius: 0px 4px 4px 4px;
  padding: 1em;
}

.linkpreview {
  // position: relative;
  width: 240px;
  height: 590px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 1px;
  margin-left: 25%;
  // bottom: 60px;
  // left: 60px;
  // object-fit: cover;
  background-image: url("../../assets/img/previewPhone.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.convBackground {
  background-color: #EFFFDF;
  padding: 0px 5px;
  border-radius: 10px 0px 10px 10px;
}

.convbuttons {
  background-color: #EFFFDF;
}

.selectBackground {
  background-color: #e1e9f8;
  padding: 0px 5px;
  border-radius: 10px 0px 10px 10px;
}

.selectbuttons {
  background-color: #e1e9f8;
}

.btnloader {
  width: 15px;
  height: 15px;
  border: 2px dotted #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.warningLabel {
  background-color: #fffec8;
  padding: 4px;
  border-radius: 4px;
  width: 74%;
  display: flex;
  // margin-bottom: 6%;
  margin-left: 5%;
}

.warningwidth {
  margin-left: -8px;
  width: 160%;
}

.warningwidthOTP {
  margin-left: -8px;
  width: 103%;
}

.useBtn {
  background: #007ec3;
  border-radius: 8px;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
}

.useBtn:hover {
  background: #007ec3;
  border-radius: 8px;
  padding: 2px 5px;
  cursor: pointer;
  color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.failedMsgBtns {
  padding: 7px;
  width: 97%;
  // border: 1px solid #FF6565;
  margin-left: 5px;
  //margin-bottom: 10px;
}

.catalogDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: "2rem";
  font-size: medium;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.radioText {
  font-size: medium;
}

.mpmtext {
  font-size: medium;
  position: relative;
  top: -22px;
  left: 15px;
}

.descriptiontext {
  position: relative;
  top: -22px;
  left: 10px;

}

.editDeleteIcon {
  margin-top: -16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Noto Sans", sans-serif !important;
}

.quickbtnscarousel {
  background-color: rgb(253, 252, 250);
  color: #007ec3;
  word-wrap: break-word;
  font-weight: 500;
  padding-left: 1%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  border-radius: 6px;
  width: 120px;
}

.bodyCarousel {
  font-size: 13px;
}

.singleCarousel {
  background-color: rgb(244 245 247);
  margin-top: 5%;
  width: 85%;
  height: 140%;
  // max-height: 115% !important;
  padding: 10px;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  word-wrap: break-word;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  max-height: 180px;
  border-radius: 10px;
  display: flex;
  /* Ensure that children are aligned horizontally */
}

.enter-auth-code {
  text-align: center;
  /* margin: auto; */
  position: relative;
  bottom: 1rem;
  left: 3rem;
}

.templateDashboardRow {
  background: #F9F9F9;

  .col {
    border-right: 1px solid #CDCDCD;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    /* margin: auto; */
    text-align: center;
    padding: 1em;
    border-radius: 7px;
    margin: 1em;

    .counts {
      color: #636363;
      font-weight: 700;
      font-size: 24px;
    }
  }
}

.droplimit {
  margin-bottom: -13px !important;
}

.non-selectable {
  user-select: none;
  /* Prevents text from being selected/copyable */
  cursor: default;
}

.sampleError {
  margin-left: 227px;
}

.advanced-options {
  margin-top: 1rem;

  .advanced-options-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #00b598;
    }
  }
}

.auto-height-input {
  display: block;
  width: 100%;
  min-height: 200px;
  /* Default minimum height */
  max-height: 500px;
  /* Optional: Limit max height */
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  /* Users can't resize manually; height is auto-managed */
  // box-sizing: border-box;
  overflow: hidden;
  /* Prevent scrollbars when resizing */
  transition: border-color 0.3s;
  overflow-y: auto;
}

.auto-height-input:focus {
  border-color: #007bff;
  /* Highlight border when focused */
  outline: none;
}

.outer-flow-input {
  display: block;
  width: 80%;
  min-height: 200px;
  max-height: 250px;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  transition: border-color 0.3s;
  overflow-y: auto;
}

.outer-flow-input:focus {
  border-color: #007bff;
  outline: none;
}

.trimText {
  word-break: break-all;
}

.productName {
  font-size: 13px;
  font-weight: 600;
  color: rgb(83, 81, 81);
}


.auto-height-input {
  display: block;
  width: 100%;
  min-height: 200px; /* Default minimum height */
  max-height: 500px; /* Optional: Limit max height */
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Users can't resize manually; height is auto-managed */
  // box-sizing: border-box;
  overflow: hidden; /* Prevent scrollbars when resizing */
  transition: border-color 0.3s;
  overflow-y: auto;
}

.auto-height-input:focus {
  border-color: #007bff; /* Highlight border when focused */
  outline: none;
}

.tapBox {
  margin-top: -30px;
}

.TempStatus {
  margin-right: -40px;
}