@import "../../variable";

.planContainer {
  font-family: 'YourFontName', sans-serif !important;
  .planSection{
    border: 1px solid lightgrey;
    padding: 8px;
    border-radius: 10px;
    margin:5px;
    .billingButton{
      border: 2px solid lightgrey;
      padding: .5em;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .planPurchase{
    position: sticky;
    background-color: white;
    position: sticky;
    padding: 3% 5%;
    z-index: 1000;
    bottom: 0px;
    border-top: 1px solid lightgrey;
    font-weight: 500;
    .planAmt{
      font-size: x-large;
      font-weight: 600;  
    }
  }
    .pricingContainer {
      max-width: 400px; /* Default compact width */
      margin: 0 auto;
      text-align: center;
      margin-bottom: 10px;
  
      .selectorWrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: $container-background;
        border-radius: 999px;
        padding: 0.2rem; /* Default padding */
        overflow: hidden;
  
        .selectorButton {
          flex: 1;
          padding: 0.3rem 0.5rem; /* Default button padding */
          border: none;
          background: transparent;
          font-size: 0.9rem; /* Default font size */
          font-weight: 500;
          color: #333;
          position: relative;
          z-index: 1;
          cursor: pointer;
          transition: color 0.3s ease;
  
          &:hover {
            color: #000;
          }
  
          &.active {
            color: #fff;
          background-color: $secondary-text-color;
          border-radius: 1em;
          transition: transform 0.3s ease;
          /* Active text color */
          }
  
          .discount {
            font-size: 0.7rem; /* Default discount text size */
            color: #f5a623;
            margin-left: 0.2rem; /* Default spacing */
          }
        }
  
        .slider {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%; /* Default slider height */
          background-color: $secondary-text-color;
          border-radius: 999px;
          z-index: 0;
          transition: transform 0.3s ease;
        }
      }
  
      .discountInfo {
        margin-top: 0.8rem; /* Default margin */
        font-size: 0.9rem; /* Default font size */
        font-weight: 600;
        color: $secondary-text-color;
      }
    }
  
    /* Responsive styles */
    @media (max-width: 768px) {
      .pricingContainer {
        max-width: 350px; /* Smaller width for tablets */
        
        .selectorWrapper {
          padding: 0.15rem; /* Less padding for smaller screens */
          
          .selectorButton {
            font-size: 0.8rem; /* Smaller button text */
            padding: 0.25rem 0.4rem; /* Adjust padding */
          }
  
          .discount {
            font-size: 0.65rem; /* Smaller discount text */
          }
        }
  
        .discountInfo {
          font-size: 0.85rem; /* Smaller discount info text */
        }
      }
    }
  
    @media (max-width: 480px) {
      .pricingContainer {
        max-width: 300px; /* Even smaller width for phones */
        
        .selectorWrapper {
          padding: 0.1rem; /* Compact padding */
          
          .selectorButton {
            font-size: 0.75rem; /* Smaller button text for phones */
            padding: 0.2rem 0.3rem; /* Adjust padding */
          }
  
          .discount {
            font-size: 0.6rem; /* Tiny discount text */
          }
        }
  
        .discountInfo {
          font-size: 0.8rem; /* Tiny discount info text */
        }
      }
    }
    .planCostContainer {
      .strikedPrices{
        color: #999;
        font-size: 1.4em;
        text-align: center;
        text-decoration: line-through;
      }
     
      .planCost {
        font-size: smaller;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #06af4333;
        height: 44.5em;
        cursor: pointer;
        border-radius: 1em;
        &.active {
          border-radius: 1em;
          // background-color: #06af4333;
          background: #CDEFD9;
          border: 1px solid $secondary-text-color;
         }
      
        .selected{
          background: white;
          font-weight: 600;
          text-align: center;
          padding: 10px;
          width: 50%;
          margin-top: auto;
          border-radius: 1em;
          border: 1px solid lightgrey;
        }
      }
    }
  
    .inactive:hover{
      box-shadow: 0px 6px 10px rgba(0,0,0,0.2);
    }
  
  }
 